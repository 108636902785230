import { Box, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { ChartCategorySettings } from 'models/charts.interface';
import { formatPrice } from 'utils/formatters';

import { Symbol } from './index.styled';

interface TooltipCategoryInfoProps {
  rowSettings: ChartCategorySettings;
  label: string;
  value?: number | null;
  transactionAmount?: number | null;
}

const TooltipCategoryInfo: FC<TooltipCategoryInfoProps> = ({
  rowSettings,
  label,
  value,
  transactionAmount = null,
}) => {
  const intl = useIntl();
  const hasTransactions = !isNil(transactionAmount);
  const price = formatPrice({ price: value });

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" alignItems="center">
        <Symbol rowSettings={rowSettings} />
        <Typography variant="body2" color="textPrimary" fontWeight={700} mt={0.5}>
          {label}
          :
        </Typography>
      </Box>

      <Box>
        {hasTransactions && (
          <Typography variant="subtitle1" color="textSecondary">
            {intl.formatMessage({ id: 'label.transactionsCount' }, { count: transactionAmount })}
          </Typography>
        )}

        <Typography variant="subtitle1" color="textSecondary">
          {hasTransactions ? `(${price})` : price}
        </Typography>
      </Box>
    </Box>
  );
};

export default TooltipCategoryInfo;
