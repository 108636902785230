import { RootState } from 'store/index';

import { ApiStatus } from 'constants/enums';
import { AdvancedTransactionSearch, CashFlowDetails } from 'models/cashFlow.interface';
import { Pagination } from 'models/pagination.interface';
import { SortOptions } from 'models/sortOptions.interface';
import { Transaction, TransactionSearch } from 'models/transaction.interface';

export const advancedSearchSelector = (state: RootState): AdvancedTransactionSearch => (
  state.cashFlow?.advancedTransactionSearch?.data || {} as AdvancedTransactionSearch
);

export const advancedTransactionsSelector = (state: RootState): TransactionSearch[] => (
  state.cashFlow?.advancedTransactionSearch?.data?.result?.content || []
);

export const isLoadingAdvancedSearchSelector = (state: RootState): boolean => (
  state.cashFlow?.advancedTransactionSearch?.status === ApiStatus.loading
);

export const advancedSearchPaginationSelector = (state: RootState): Pagination => (
  state.cashFlow?.advancedTransactionSearch?.pagination
);

export const advancedSearchSortOptionsSelector = (state: RootState):SortOptions => (
  state.cashFlow?.advancedTransactionSearch?.sortOptions
);

export const advancedSearchSearchSelector = (state: RootState):string => (
  state.cashFlow?.advancedTransactionSearch?.search
);

export const cashFlowCreditTransactionsSelector = (state: RootState): Transaction[] => (
  state.cashFlow?.cashFlow?.credit?.data?.result?.content || []
);

export const summaryTransactionsSelector = (state: RootState): Transaction[] => (
  state.cashFlow?.summaryTransactions?.data?.result?.content || []
);

export const isLoadingSummaryTransactionsSelector = (state: RootState): boolean => (
  state.cashFlow?.summaryTransactions?.status === ApiStatus.loading
);

export const summaryTransactionsPaginationSelector = (state: RootState): Pagination => (
  state.cashFlow?.summaryTransactions?.pagination
);

export const summaryTransactionsSortOptionsSelector = (state: RootState):SortOptions => (
  state.cashFlow?.summaryTransactions?.sortOptions
);

export const totalCreditValueSelector = (state: RootState): number => (
  state.cashFlow?.cashFlow?.credit?.data?.totalCreditValue || 0
);

export const cashFlowDebitTransactionsSelector = (state: RootState): Transaction[] => (
  state.cashFlow?.cashFlow?.debit?.data?.result?.content || []
);

export const totalDebitValueSelector = (state: RootState): number => (
  state.cashFlow?.cashFlow?.debit?.data?.totalDebitValue || 0
);

export const isLoadingCashFlowCreditSelector = (state: RootState): boolean => (
  state.cashFlow?.cashFlow?.credit?.status === ApiStatus.loading
);

export const isLoadingCashFlowDebitSelector = (state: RootState): boolean => (
  state.cashFlow?.cashFlow?.debit?.status === ApiStatus.loading
);

export const cashFlowCreditPaginationSelector = (state: RootState): Pagination => (
  state.cashFlow?.cashFlow?.credit?.pagination
);

export const cashFlowDebitPaginationSelector = (state: RootState): Pagination => (
  state.cashFlow?.cashFlow?.debit?.pagination
);

export const cashFlowCreditSortOptionsSelector = (state: RootState):SortOptions => (
  state.cashFlow?.cashFlow?.credit?.sortOptions
);

export const cashFlowDebitSortOptionsSelector = (state: RootState):SortOptions => (
  state.cashFlow?.cashFlow?.debit?.sortOptions
);

export const cashFlowDetailsSelector = (state: RootState):CashFlowDetails => (
  state.cashFlow?.cashFlowDetails?.data
);

export const isLoadingCashFlowDetailsSelector = (state: RootState):boolean => (
  state.cashFlow?.cashFlowDetails?.status === ApiStatus.loading
);
