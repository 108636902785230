import { ComponentType, LazyExoticComponent } from 'react';

import ComingSoon from 'components/_ComingSoon';
import Content from 'components/_Content';

import envConfig from 'configuration/env';

const { env } = envConfig;

export const existingFlags = {
  moveMoney: 'move-money',
  batchTransactions: 'batch-transactions',
  quickMoveMoney: 'quick-move-money',
  userCompanies: 'user-companies',
  integratedAccounts: 'integrated-accounts',
  subscriptions: 'subscriptions',
  fundsReturn: 'fundsReturn',
};

/**
 * Flag routes
 */
const flagRoutes: { [key: string]: string[] } = {
  LOCAL: [existingFlags.moveMoney, existingFlags.userCompanies, existingFlags.subscriptions],
  DEV: [existingFlags.moveMoney, existingFlags.userCompanies],
  STAGE: [existingFlags.moveMoney, existingFlags.userCompanies, existingFlags.subscriptions],
  PROD: [existingFlags.moveMoney, existingFlags.userCompanies],
};

const flagFeature: { [key: string]: string[] } = {
  LOCAL: [
    existingFlags.moveMoney,
    existingFlags.batchTransactions,
    existingFlags.integratedAccounts,
    existingFlags.subscriptions,
    existingFlags.fundsReturn,
  ],
  DEV: [
    existingFlags.moveMoney,
    existingFlags.batchTransactions,
    existingFlags.integratedAccounts,
    existingFlags.subscriptions,
    existingFlags.fundsReturn,
  ],
  STAGE: [existingFlags.moveMoney, existingFlags.subscriptions],
  PROD: [],
};

export const isRouteEnabled = (feature: string) => {
  const flags = flagRoutes[env] || [];
  return flags?.includes(feature);
};

export const isFeatureEnabled = (feature: string) => {
  const flags = flagFeature[env] || [];
  return flags?.includes(feature);
};

export const renderRouteComponent = (
  feature: string,
  Component: LazyExoticComponent<ComponentType<any>>,
  description?: string,
) => {
  const isEnabled = isRouteEnabled(feature);

  return isEnabled
    ? <Component />
    : <Content><ComingSoon description={description} /></Content>;
};

export const getRecaptchaSize = (searchParams: URLSearchParams) => {
  if (env !== 'PROD' && searchParams.get('reason') === 'QA') {
    return 'invisible';
  }

  return 'normal';
};

/**
 * Flag emails
 * This is a temporary solution for admin portal until we'll have BE support
 */

export const operatorsEmails = [
  'alina@primaryaccount.com',
  'sorin@primaryaccount.com',
];
