import { isNil } from 'lodash';

import {
  AmountComparison,
  CashFlowSearchType,
  DateFormats,
  DateType,
  DefaultType,
} from 'constants/enums';
import { CashFlowFilterHeader } from 'models/cashFlow.interface';
import { formatDate } from 'utils/formatters';
import { extractNumberFromNumericFormat } from 'utils/numbers';
import { AdvancedTransactionFilterSchema } from 'utils/validation/transactionFormSchema';

import { FILTER_SIGN_BY_AMOUNT_COMPARISON } from './constants';

export const formatFiltersForServer = (filters: AdvancedTransactionFilterSchema) => {
  let newFilters = {};
  const amountCondition = filters?.amountCondition as AmountComparison;

  if (!isNil(filters?.amountRange?.min) && amountCondition) {
    newFilters = {
      ...newFilters,
      ...(amountCondition === AmountComparison.between
        ? {
          amountFrom: filters?.amountRange?.min ? extractNumberFromNumericFormat(filters?.amountRange?.min) : 0,
          amountTo: filters?.amountRange?.max ? extractNumberFromNumericFormat(filters?.amountRange?.max) : 0,
        } : {
          amountFilterValue: filters?.amountRange?.min ? extractNumberFromNumericFormat(filters?.amountRange?.min) : 0,
          amountFilterSign: FILTER_SIGN_BY_AMOUNT_COMPARISON?.[amountCondition],
        }),
    };
  }

  return {
    ...newFilters,
    ...(filters?.dateRange?.type !== DateType.custom
      ? {
        interval: filters?.dateRange?.type as DateType,
      } : {}),
    ...(filters?.keywords ? { description: filters?.keywords } : {}),
    ...(filters?.transactionType !== DefaultType.all
      ? { type: filters?.transactionType as CashFlowSearchType }
      : {}),
    ...(filters?.dateRange?.type === DateType.custom
      ? {
        dateFrom: filters?.dateRange?.from
          ? formatDate(new Date(filters.dateRange.from), DateFormats.transactionDate)
          : undefined,
        dateTo: filters?.dateRange?.to
          ? formatDate(new Date(filters.dateRange.to), DateFormats.transactionDate)
          : undefined,
      } : {}),
  };
};

export const formatFiltersForHeader = (filters: AdvancedTransactionFilterSchema): CashFlowFilterHeader => ({
  interval: filters?.dateRange?.type as DateType,
  type: filters?.transactionType as CashFlowSearchType,
  amountCondition: filters?.amountCondition as AmountComparison,
  amountFrom: filters?.amountRange?.min
    ? extractNumberFromNumericFormat(filters?.amountRange?.min)
    : null,
  amountTo: filters?.amountRange?.max
    ? extractNumberFromNumericFormat(filters?.amountRange?.max)
    : null,
  keywords: filters?.keywords,
  ...(filters?.dateRange?.type === DateType.custom
    ? {
      dateFrom: filters?.dateRange?.from
        ? formatDate(new Date(filters.dateRange.from), DateFormats.transactionDate)
        : undefined,
      dateTo: filters?.dateRange?.to
        ? formatDate(new Date(filters.dateRange.to), DateFormats.transactionDate)
        : undefined,
    } : {}),
});
