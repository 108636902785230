import { useTheme } from '@mui/material';
import { ScaleBand, select } from 'd3';
import { FC, useEffect, useRef } from 'react';

import DefaultBankPicture from 'assets/images/default-bank-picture.svg';
import { PositionType } from 'constants/enums';
import { getAxisBasedOnPosition } from 'utils/chart';

export interface BankXYAxisProps {
  scale: ScaleBand<string>;
  transform: string;
  data: any; // TODO: define the type
  position?: PositionType;
  hideAxe?: boolean;
  onTickFormat: (d: any) => string|undefined;
  tickValues?: any;
}

const BankXYAxis:FC<BankXYAxisProps> = ({
  scale,
  transform,
  data,
  position = PositionType.bottom,
  hideAxe,
  onTickFormat,
  tickValues = null,
}) => {
  const ref = useRef<SVGGElement>(null);
  const theme = useTheme();

  useEffect(() => {
    if (ref.current) {
      const axisHandler = getAxisBasedOnPosition(position);

      if (hideAxe) {
        select(ref.current)
          .call(axisHandler(scale).tickSize(0).tickValues(tickValues))
          .call((g) => g.select('.domain').remove());
      } else {
        select(ref.current)
          .call(axisHandler(scale).tickSize(0).tickValues(tickValues));
      }

      // render image
      select(ref.current)
        .selectAll('.tick')
        .data(data)
        .append('svg:image')
        .merge(select(ref.current).selectAll('.tick image'))
        .attr('href', (d: any) => onTickFormat(d) || DefaultBankPicture)
        .attr('width', 16)
        .attr('height', 16)
        .attr('x', -8)
        .attr('y', 12)
        .merge(
          select(ref.current)
            .selectAll('.tick text')
            .style('display', 'none'),
        );
      select(ref.current)
        .select('path')
        .style('stroke', theme.palette.general.darkGrey1);
    }
  }, [scale, tickValues]);

  return <g ref={ref} transform={transform} />;
};

export default BankXYAxis;
