import { alpha, styled } from '@mui/material/styles';
import { get } from 'lodash';

import { ChartCategorySettings } from 'models/charts.interface';

export const Symbol = styled('div')<{
  rowSettings: ChartCategorySettings;
}>(({
  theme,
  rowSettings,
}) => ({
  height: rowSettings.isLine ? '2px' : '12px',
  width: '12px',
  marginRight: theme.spacing(2),
  backgroundColor: rowSettings.opacity
    ? alpha(get(theme.palette, rowSettings.primaryColor), rowSettings.opacity)
    : get(theme.palette, rowSettings.primaryColor),
  borderRadius: rowSettings.isLine ? '2px' : '4px',
  border: (rowSettings.isDashed && rowSettings.borderColor)
    ? `1px dashed ${get(theme.palette, rowSettings.borderColor)}`
    : '',
}));
