import MuiCircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

export const CircularProgress = styled(
  MuiCircularProgress,
  { shouldForwardProp: (props) => props !== 'gradientId' },
)<{ gradientId: string }>(({ gradientId }) => ({
  'svg circle': {
    stroke: `url(#${gradientId})`,
    strokeLinecap: 'round',
  },
}));
