import { Box } from '@mui/material';
import MuiMenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';

export const MenuWrapper = styled(Box)(() => ({
  overflowY: 'auto',
}));

export const MenuItem = styled(
  MuiMenuItem,
  { shouldForwardProp: (props) => props !== 'isActive' },
)<{ isActive?: boolean }>(({ theme, isActive = false }) => ({
  backgroundColor: isActive
    ? alpha(theme.palette.primary.main, 0.1)
    : theme.palette.common.white,
}));
