export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export enum DateFormats {
  defaultDate = 'MM/dd/yyyy',
  detailedDateTime = 'MM/dd/yyyy hh:mm a',
  detailedDayDate = 'MMM dd, yyyy hh:mm a',
  longDate = 'MMM dd, yyyy',
  shortDate = 'MMM dd',
  onlyWeekday = 'eeee',
  onlyDay = 'dd',
  onlyMonth = 'MMM',
  monthAndYear = 'MM/yyyy',
  transactionDate = 'yyyy-MM-dd',
  yearMonth = 'yyyy-MM',
}

export enum ApiStatus {
  idle = 'idle',
  loading = 'loading',
  failed = 'failed',
}

export enum ElementSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum LocalStorageKeys {
  fiservSessionId = 'fiservSessionId',
  loginWorkspaces = 'loginWorkspaces',
  jwtToken = 'jwtToken',
  viewAs = 'viewAs',
  viewAsWorkspaces = 'viewAsWorkspaces',
  lastVerificationEmailDate = 'lastVerificationEmailDate',
  lastResetEmailDate = 'lastResetEmailDate',
  refreshAccountsTimer = 'refreshAccountsTimer',
  hideBrokenAccountsModal = 'hideBrokenAccountsModal',
  hideBetaDetails = 'hideBetaDetails',
  hidePendingApprovalTransactions = 'hidePendingApprovalTransactions'
}

export enum FiservRouteType {
  add = 'add',
  relink = 'relink',
}

export enum BankAccountType {
  allTypes = 'allTypes',
  favourite = 'favourite',
  checking = 'CHECKING',
  saving = 'SAVING',
  credit = 'CREDIT',
  debit = 'DEBIT',
  prepaid = 'PREPAID',
  gift = 'GIFT',
  dda = 'DDA',
  cca = 'CCA',
  inv = 'INV',
  oaa = 'OAA',
  ola = 'OLA',
  loc = 'LOC',
  equ = 'EQU',
  ila = 'ILA',
  ins = 'INS',
  bpa = 'BPA',
  sda = 'SDA',
  bnp = 'BNP',
}

export enum BankAccountCategory {
  wallet = 'WALLET',
  external = 'EXTERNAL',
  integrated = 'INTEGRATED',
}

export enum CurrencyType {
  usd = 'USD',
}

export enum CurrencySymbol {
  usd = '$',
}

export enum ConfirmationTypes {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
  Scheduled = 'scheduled',
  Custom = 'custom',
}

export enum TransactionSolution {
  ach = 'ach',
  pushToCard = 'push-to-card',
  transfer = 'transfer',
  wire = 'wire',
}

export enum TransactionDirection {
  request = 'REQUEST',
  send = 'SEND',
}

export enum TransactionType {
  credit = 'CREDIT',
  debit = 'DEBIT',
  cash = 'CASH',
  request = 'REQUEST',
  send = 'SEND',
  adjustment = 'ADJUSTMENT',
}

export enum AdjustmentReason {
  adjustmentReason = 'DEPOSIT_TRANSACTION',
}

export enum TransactionRecurrence {
  doesNotRepeat = 'DOES_NOT_REPEAT',
  daily = 'DAILY',
  weekly = 'WEEKLY',
  monthly = 'MONTHLY',
  yearly = 'YEARLY',
  everyWeekday = 'EVERY_WEEKDAY',
  everyBusinessDay = 'EVERY_BUSINESS_DAY',
  custom = 'CUSTOM',
  customCreated = 'CUSTOM_CREATED',
}

export enum AmPm {
  am = 'am',
  pm = 'pm',
}

export enum TransactionRequestor {
  employee = 'EMPLOYEE',
  businessAccount = 'BUSINESS_ACCOUNT',
  recipient = 'RECIPIENT',
}

export enum FaAccountType {
  bankAccount = 'BANK_ACCOUNT',
  recipient = 'RECIPIENT',
}

export enum KorTransactionType {
  mlt = 'MLT',
  slt = 'SLT',
}

export enum TransactionReversalReason {
  duplicatePayment = 'DUPLICATE_PAYMENT',
  incorrectRecipient = 'INCORRECT_RECIPIENT',
  incorrectAmount = 'INCORRECT_AMOUNT',
}

export enum TransactionStatusType {
  approved = 'APPROVED',
  cancelled = 'CANCELLED',
  cleared = 'CLEARED',
  declined = 'DECLINED',
  error = 'ERROR',
  new = 'NEW',
  pending = 'PENDING',
  processing = 'PROCESSING',
  settled = 'SETTLED',
  reversed = 'REVERSED',
  active = 'ACTIVE',
  recurrence = 'RECURRENCE',
  finished = 'FINISHED',
  scheduled = 'SCHEDULED',
  deleted = 'DELETED',
  suspended = 'SUSPENDED',
  depositWithheld = 'DEPOSIT_WITHHELD',
  rejected = 'REJECTED',
  failed = 'FAILED',
}

export enum TransactionSettlementPriority {
  sameDay = 'SAME_DAY',
  nextDay = 'NEXT_DAY',
  immediate = 'IMMEDIATE',
  scheduled = 'SCHEDULED',
}

export enum PermissionRoles {
  owner = 'Owner',
  admin = 'Admin',
  finance = 'Finance',
  manager = 'Manager',
  clerk = 'Clerk',
  viewOnly = 'View-only',
}

export enum BankAccountsSortByType {
  balanceHighLow = 'balanceHighLow',
  balanceLowHigh = 'balanceLowHigh',
  instituteNameAZ = 'instituteNameAZ',
  instituteNameZA = 'instituteNameZA',
}

export enum BankAccountsGroupByType {
  accountType = 'accountType',
  bank = 'bank',
  noGrouping = 'noGrouping',
}

export enum AuthorityType {
  userOwner = 'USER_OWNER',
  userAdmin = 'USER_ADMIN',
  userManager = 'USER_MANAGER',
  userViewer = 'USER_VIEWER',
  opsAdmin = 'OPS_ADMIN',
  opsManager = 'OPS_MANAGER',
  opsViewer = 'OPS_VIEWER',
}

export enum BalanceTrend {
  MonthToDate = 'MONTH_TO_DATE',
  LastFourWeeks = 'LAST_FOUR_WEEKS',
  QtrToDate = 'QUARTER_TO_DATE',
  Last3Month = 'LAST_THREE_MONTHS',
  LastYear = 'YEAR',
}

export enum FlowActivityType {
  inflow = 'inflow',
  outflow = 'outflow',
  pendingInflow = 'pendingInflow',
  pendingOutflow = 'pendingOutflow',
}

export enum PositionType {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right',
}

export enum ChartCategoryType {
  inflow = 'inflow',
  availableBalance = 'availableBalance',
  outflow = 'outflow',
  totalBalance = 'totalBalance',
  accountBalance = 'accountBalance',
  accountDebits = 'accountDebits',
  accountCredits = 'accountCredits',
  thirtyDaysMovingAverage = 'thirtyDaysMovingAverage',
  sixtyDaysMovingAverage = 'sixtyDaysMovingAverage',
  ninetyDaysMovingAverage = 'ninetyDaysMovingAverage',
  totalTransactions = 'totalTransactions',
}

export enum TransactionFilterTypes {
  inflow = 'inflow',
  outflow = 'outflow',
}

export enum TransactionFilterDirections {
  send = 'send',
  request = 'request',
}

export enum TransactionFilterAmount {
  min = 'amountRange.min',
  max = 'amountRange.max',
}

export enum FilterDate {
  from = 'dateRange.from',
  to = 'dateRange.to',
}

export enum FirebaseEmailMode {
  resetPassword = 'resetPassword',
  verifyEmail = 'verifyEmail',
  invalidCode = 'invalidCode',
}

export enum UserProfileModelModes {
  upload = 'upload',
  edit = 'edit',
}

export enum UserAgreementTypes {
  customerTerms = 'CUSTOMER_TERMS',
  customerPrivacy = 'CUSTOMER_PRIVACY',
}

export enum DefaultType {
  all = 'ALL',
}

export enum UsersSortByType {
  name = 'name',
  email = 'email',
  id = 'id',
  authority = 'authority',
  phoneNumber = 'phoneNumber',
}

export enum InvitationStatus {
  accepted = 'ACCEPTED',
  new = 'NEW',
  visited = 'VISITED',
}

export enum TableHeaderColors {
  general = 'general',
  warning = 'warning',
}

export enum CustomerStatus {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
  suspended = 'SUSPENDED',
  closed = 'CLOSED',
}

export enum RecurrenceEndPeriod {
  never = 'NEVER',
  on = 'ON',
  after = 'AFTER',
}

export enum CustomMonthlyRecurrence {
  monthlyOnCurrentDay = 'MONTHLY_ON_CURRENT_DAY',
  monthlyOnCurrentWeek = 'MONTHLY_ON_CURRENT_WEEK',
  monthlyOnTheLastWeek = 'MONTHLY_ON_LAST_WEEK',
}

export enum AmountType {
  exactAmount = 'exactAmount',
  exactAmountLeft = 'exactAmountLeft',
  percentage = 'percentage',
}

export enum FinancialAccountCategory {
  external = 'EXTERNAL',
  internal = 'INTERNAL',
  integrated = 'INTEGRATED',
}

export enum AccountHolderType {
  customer = 'CUSTOMER',
  recipient = 'RECIPIENT',
}

export enum FinancialAccountType {
  bank = 'BANK',
  card = 'CARD',
}

export enum FinancialAccountSubtype {
  checking = 'CHECKING',
  saving = 'SAVING',
  credit = 'CREDIT',
  debit = 'DEBIT',
  gift = 'GIFT',
  prepaid = 'PREPAID',
}

export enum MovingAverageType {
  thirtyDaysMovingAverage = 'thirtyDaysMovingAverage',
  sixtyDaysMovingAverage = 'sixtyDaysMovingAverage',
  ninetyDaysMovingAverage = 'ninetyDaysMovingAverage',
}

export enum ActivityChartType {
  inflow = 'inflow',
  outflow = 'outflow',
  availableBalance = 'availableBalance',
}

export enum TransactionDetailsActionType {
  cancel = 'cancel',
  pause = 'pause',
  resume = 'resume',
  confirmPause = 'confirmPause'
}

export enum ScheduledTransactionStatus {
  suspend = 'SUSPENDED',
  active = 'ACTIVE',
  deleted = 'DELETED',
}

export enum TransactionApprovalStatus {
  pending = 'PENDING',
  approved = 'APPROVED',
  rejected = 'REJECTED',
  failed = 'FAILED',
}

export enum RuleStatus {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
}

export enum RuleHistoryStatus {
  pending = 'PENDING',
  sent = 'SENT',
  delivered = 'DELIVERED',
  failed = 'FAILED',
  submitted = 'SUBMITTED',
}

export enum RuleMatch {
  match = 'MATCH',
  noMatch = 'NO_MATCH',
}

export enum RuleTimeframeType {
  anyTime = 'anyTime',
  customTime = 'customTime',
}

export enum CriteriaCondition {
  equal = 'EQUAL',
  greaterEqual = 'GREATER_EQUAL',
  between = 'BETWEEN',
  greaterThan = 'GREATER_THAN',
  lessEqual = 'LESS_EQUAL',
  lessThan = 'LESS_THAN',
}

export enum CriteriaAccount {
  bank = 'bank',
  bankAccount = 'bankAccount',
}

export enum AddAccountActionType {
  linkAccount = 'linkAccount',
  applyForAccount = 'applyForAccount',
}

export enum BannerType {
  warning = 'warning',
  error = 'error',
}

export enum DocumentType {
  formation = 'FORMATION',
  tax = 'TAX',
  ownership = 'OWNERSHIP',
  passport = 'PASSPORT',
  driverLicense = 'DRIVING_LICENSE',
  other = 'OTHER',
}

export enum IdentityApplicationStatus {
  awaitingInformation = 'AWAITING_INFORMATION',
  completed = 'COMPLETED',
  error = 'ERROR',
  new = 'NEW',
  pending = 'PENDING',
  pendingReview = 'PENDING_REVIEW',
  processing = 'PROCESSING',
}

export enum AccountType {
  individualCustomer = 'INDIVIDUAL_CUSTOMER',
  businessCustomer = 'BUSINESS_CUSTOMER',
}

export enum ApplicationEntityType {
  beneficiary = 'BENEFICIARY',
  businessCustomer = 'BUSINESS_CUSTOMER',
  individualCustomer = 'INDIVIDUAL_CUSTOMER',
}

export enum KybSteps {
  companyDetails = 'companyDetails',
  companyAddress = 'companyAddress',
  documents = 'documents',
  beneficiaries = 'beneficiaries',
  review = 'review'
}

export enum AddressType {
  workplace = 'WORKPLACE',
  residential = 'RESIDENTIAL',
  mailing = 'MAILING',
  billing = 'BILLING',
  physical = 'PHYSICAL',
  shipping = 'SHIPPING'
}

export enum DocumentStatus {
  new = 'NEW',
  uploaded = 'UPLOADED',
  malicious = 'MALICIOUS',
  scanned = 'SCANNED',
  incomplete = 'INCOMPLETE',
}

export enum CompanyType {
  business = 'BUSINESS',
  individual = 'INDIVIDUAL',
}

export enum Sex {
  female = 'FEMALE',
  male = 'MALE',
  nonbinary = 'NONBINARY',
}

export enum PhoneNumberType {
  home = 'HOME',
  mobile = 'MOBILE',
  work = 'WORK',
}

export enum KybCoverType {
  inReview = 'inReview',
  rejected = 'rejected',
}

export enum KybStatus {
  pending = 'PENDING',
  inProgress = 'IN_PROGRESS',
  submitted = 'SUBMITTED',
  completed = 'COMPLETED',
}

export enum MappedKybStatus {
  draft = 'DRAFT',
  inProgress = 'IN_PROGRESS',
  inReview = 'IN_REVIEW',
  rejected = 'REJECTED',
  accepted = 'ACCEPTED',
}

export enum DocumentHolderType {
  businessAccount = 'BUSINESS_ACCOUNT',
  customer = 'CUSTOMER',
  beneficiary = 'BENEFICIARY'
}

export enum SubscriptionType {
  basic = 'BASIC',
  standard = 'STANDARD',
  premium = 'PREMIUM',
}

export enum PaymentType {
  card = 'CARD',
  bank = 'BANK_ACCOUNT',
}

export enum LimitType {
  single = 'SINGLE',
  daily = 'DAILY',
  weekly = 'WEEKLY',
  monthly = 'MONTHLY',
}

export enum ScopeType {
  customer = 'CUSTOMER',
  faKfa = 'FA_KFA',
  childBa = 'CHILD_BA',
}

export enum EntityLimitType {
  customer = 'CUSTOMER',
  bankAccount = 'BANK_ACCOUNT',
}

export enum ApplicationOutcome {
  approved = 'Approved',
  denied = 'Denied',
}

export enum RecipientType {
  business = 'BUSINESS',
  individual = 'INDIVIDUAL',
}

export enum RecipientState {
  active = 'ACTIVE',
  suspended = 'SUSPENDED',
  deleted = 'DELETED',
}

export enum ErrorCodes {
  notFound = 404,
  permissionDenied = 403,
}

export enum UserPreferenceTypes {
 TransactionApproval = 'TRANSACTION_APPROVAL'
}

export enum AmountComparison {
  equal = 'EQUAL',
  greaterEqual = 'GREATER_EQUAL',
  between = 'BETWEEN',
  greaterThan = 'GREATER_THAN',
  lessEqual = 'LESS_EQUAL',
  lessThan = 'LESS_THAN',
}

export enum DateType {
  oneDay = 'ONE_DAY',
  sevenDays = 'SEVEN_DAYS',
  oneMonth = 'ONE_MONTH',
  fourMonths = 'FOUR_MONTHS',
  monthToDate = 'MONTH_TO_DATE',
  yearToDate = 'YEAR_TO_DATE',
  custom = 'CUSTOM',
}

export enum TopUpFundingStatus {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
}

export enum CashFlowSearchType {
  cash = 'CASH',
  credit = 'CREDIT',
  debit = 'DEBIT',
  deposit = 'DEPOSIT',
  fee = 'FEE',
  openingBalance = 'OPENING_BALANCE',
  send = 'SEND',
  request = 'REQUEST',
  reversal = 'REVERSAL',
  return = 'RETURN'
}

export enum FilterSign {
  eq = 'EQ',
  ne = 'NE',
  gt = 'GT',
  lt = 'LT',
  gte = 'GTE',
  lte = 'LTE',
  like = 'LIKE',
  nlike = 'NLIKE',
  ilke = 'ILIKE',
  nilike = 'NILIKE'
}

export enum InsightsComponents {
  moneyMovement = 'moneyMovement',
  cashflow = 'cashflow',
  advancedSearch = 'advancedSearch',
}
