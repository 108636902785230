import { DateFormats, DateType } from 'constants/enums';
import { formatDate } from 'utils/formatters';
import { CashflowSchema } from 'utils/validation/cashflowSchema';

export const formatFiltersForServer = (filters: CashflowSchema) => ({
  ...(filters?.dateRange?.type !== DateType.custom
    ? {
      interval: filters?.dateRange?.type as DateType,
    } : {}),
  ...(filters?.dateRange?.type === DateType.custom
    ? {
      dateFrom: filters?.dateRange?.from
        ? formatDate(new Date(filters.dateRange.from), DateFormats.transactionDate)
        : undefined,
      dateTo: filters?.dateRange?.to
        ? formatDate(new Date(filters.dateRange.to), DateFormats.transactionDate)
        : undefined,
    } : {}),
});
