import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

export const CreateBatchTransactions = lazy(
  () => CustomLazyLoader(
    () => import(
      /* webpackChunkName: 'CreateBatchTransactions' */
      'modules/create-batch-transactions/containers/CreateBatchTransactions'
    ),
    'CreateBatchTransactions',
  ),
);
