import { styled } from '@mui/material/styles';

import { NAVBAR_HEIGHT } from 'constants/general';

export const EmptyStateWrapper = styled('div')(() => ({
  height: `calc(100vh - ${NAVBAR_HEIGHT}px - 200px)`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
