import {
  runDeleteApiRequest, runGetApiRequest, runPostApiRequest, runPostApiRequestFB, runUpdateApiRequest,
} from 'services/Api';

import {
  CANCEL_INVITATION_API,
  CURRENT_USER_API,
  INVITE_DETAILS_USER_API,
  INVITE_USER_API,
  SEND_WELCOME_EMAIL_API,
  RESEND_INVITATION_API,
  SEND_EMAIL_CONFIRMATION_API,
  USER_DETAILS_API,
  USER_JOIN_INVITATION_API,
  USERS_API,
  SEND_RESET_PASSWORD_EMAIL_API,
  SEND_RESET_CONFIRMATION_EMAIL_API,
  USER_PERMISSIONS_API,
  USER_FILTER_PREFERENCES_API,
} from 'constants/apiRoutes';
import { CreateUserFilterPreference } from 'models/filterPreference.interface';
import { User } from 'models/user.interface';
import { formatRoute } from 'utils/formatters';

export const getLoggedUser = () => (
  runGetApiRequest(CURRENT_USER_API)
);

export const updateLoggedUser = (data: Partial<User>) => runUpdateApiRequest(USERS_API, data);

export const inviteUser = async (
  params: { [key: string]: string | number },
  payload: { [key: string]: string | string[] },
) => {
  const endpoint = formatRoute(INVITE_USER_API, params);

  return (
    runUpdateApiRequest(endpoint, payload)
  );
};

export const getInvitationDetails = async (
  params: { [key: string]: string | number },
) => {
  const endpoint = formatRoute(INVITE_DETAILS_USER_API, params);

  return (
    runGetApiRequest(endpoint)
  );
};

export const joinUsingInvitation = async (
  params: { [key: string]: string | number },
  queryParams: { userId: string },
) => {
  const endpoint = formatRoute(USER_JOIN_INVITATION_API, params);

  return (
    runUpdateApiRequest(endpoint, {}, queryParams)
  );
};

export const getUserDetails = async (workspaceId: string, userId: string) => {
  const endpoint = formatRoute(USER_DETAILS_API, { workspaceId, userId });
  return runGetApiRequest(endpoint);
};

export const resendInvitation = async (invitationId: string) => {
  const endpoint = formatRoute(RESEND_INVITATION_API, { invitationId });
  return runPostApiRequest(endpoint);
};

export const cancelInvitation = async (invitationId: string) => {
  const endpoint = formatRoute(CANCEL_INVITATION_API, { invitationId });
  return runDeleteApiRequest(endpoint);
};

export const sendEmailConfirmation = async (email: string) => (
  runPostApiRequestFB(`${SEND_EMAIL_CONFIRMATION_API}?email=${encodeURIComponent(email)}`)
);

export const sendWelcomeEmail = async (email: string) => {
  const encodedEmail = encodeURIComponent(email);
  return runPostApiRequestFB(`${SEND_WELCOME_EMAIL_API}?email=${encodedEmail}`);
};

export const sendResetPassword = async (email: string) => (
  runPostApiRequest(`${SEND_RESET_PASSWORD_EMAIL_API}?email=${encodeURIComponent(email)}`)
);

export const sendResetConfirmation = async (email: string) => (
  runPostApiRequest(`${SEND_RESET_CONFIRMATION_EMAIL_API}?email=${encodeURIComponent(email)}`)
);

export const getUserPermissions = async () => (
  runGetApiRequest(USER_PERMISSIONS_API)
);

export const getUserFilterPreferences = async (workspaceId: string, userId: string, pageName: string) => {
  const endpoint = formatRoute(USER_FILTER_PREFERENCES_API, { workspaceId, userId });
  return runGetApiRequest(`${endpoint}?pageName=${pageName}`);
};

export const saveUserFilterPreferences = async (
  workspaceId: string,
  userId: string,
  payload: CreateUserFilterPreference,
) => {
  const endpoint = formatRoute(USER_FILTER_PREFERENCES_API, { workspaceId, userId });
  return runPostApiRequest(endpoint, payload);
};
