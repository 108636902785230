import { AmountComparison, FilterSign } from 'constants/enums';

export const FILTER_SIGN_BY_AMOUNT_COMPARISON = {
  [AmountComparison.equal]: FilterSign.eq,
  [AmountComparison.greaterEqual]: FilterSign.gte,
  [AmountComparison.greaterThan]: FilterSign.gt,
  [AmountComparison.lessEqual]: FilterSign.lte,
  [AmountComparison.lessThan]: FilterSign.lt,
  [AmountComparison.between]: FilterSign.eq,
};
