import ACHIcon from 'assets/icons/ACHIcon';
import BankIcon from 'assets/icons/BankIcon';
import CardIcon from 'assets/icons/CardIcon';
import ReceiveMoney from 'assets/icons/ReceiveMoney';
import SendIcon from 'assets/icons/SendIcon';
import TransferIcon from 'assets/icons/TransferIcon';
import {
  AmountType,
  TransactionSolution,
  TransactionDirection, TransactionSettlementPriority,
} from 'constants/enums';
import {
  TransactionTypeOption,
  TransactionSolutionOption,
  AchEntryType,
} from 'models/moveMoney.interface';

export const amountTypeOptions = [
  { label: 'label.exactAmount', value: AmountType.exactAmount },
  { label: 'label.percentage', value: AmountType.percentage },
  { label: 'label.exactAmountLeft', value: AmountType.exactAmountLeft, disabled: true },
];

export const solutionAvailabilityError: { [key in TransactionSolution]?: string } = {
  [TransactionSolution.transfer]: 'label.transferOnlyBetweenInternal',
  [TransactionSolution.wire]: 'label.wireOnlyFromInternalToExternal',
  [TransactionSolution.ach]: 'label.achBetweenInternal',
};

export const SEND_MONEY_OPTION: TransactionTypeOption = {
  type: TransactionDirection.send,
  label: 'label.send',
  longLabel: 'label.sendMoney',
  icon: <SendIcon size={20} />,
};

export const REQUEST_MONEY_OPTION: TransactionTypeOption = {
  type: TransactionDirection.request,
  label: 'label.request',
  longLabel: 'label.requestMoney',
  icon: <ReceiveMoney size={20} />,
};

export const ACH_OPTION: TransactionSolutionOption = {
  type: TransactionSolution.ach,
  label: 'label.ach',
  icon: <ACHIcon size={20} />,
  config: [
    {
      timeInDays: 3,
      fee: 5,
      currency: '$',
      solution: TransactionSolution.ach,
      settlementPriority: TransactionSettlementPriority.nextDay,
    },
    {
      timeInDays: 1,
      fee: 10,
      currency: '$',
      solution: TransactionSolution.ach,
      settlementPriority: TransactionSettlementPriority.sameDay,
    },
  ],
};

export const PUSH_TO_CARD_OPTION: TransactionSolutionOption = {
  type: TransactionSolution.pushToCard,
  label: 'label.pushToCard',
  icon: <CardIcon size={20} />,
  isDisabledFor: [TransactionDirection.request],
  isDisabled: true,
  config: [
    {
      timeInDays: 3,
      fee: 5,
      currency: '$',
      solution: TransactionSolution.pushToCard,
      settlementPriority: TransactionSettlementPriority.nextDay,
    },
    {
      timeInDays: 1,
      fee: 10,
      currency: '$',
      solution: TransactionSolution.pushToCard,
      settlementPriority: TransactionSettlementPriority.sameDay,
    },
  ],
};

export const TRANSFER_OPTION: TransactionSolutionOption = {
  type: TransactionSolution.transfer,
  label: 'label.transfer',
  icon: <TransferIcon size={20} />,
  isDisabledFor: [TransactionDirection.request],
  config: [
    {
      timeInDays: 3,
      fee: 5,
      currency: '$',
      solution: TransactionSolution.transfer,
      settlementPriority: TransactionSettlementPriority.nextDay,
    },
    {
      timeInDays: 1,
      fee: 10,
      currency: '$',
      solution: TransactionSolution.transfer,
      settlementPriority: TransactionSettlementPriority.sameDay,
    },
  ],
};

export const WIRE_OPTION: TransactionSolutionOption = {
  type: TransactionSolution.wire,
  label: 'label.wire',
  icon: <BankIcon size={20} />,
  isDisabled: false,
  isDisabledFor: [TransactionDirection.request],
  config: [
    {
      timeInDays: 3,
      fee: 10,
      currency: '$',
      solution: TransactionSolution.wire,
      settlementPriority: TransactionSettlementPriority.nextDay,
    },
    {
      timeInDays: 1,
      fee: 20,
      currency: '$',
      solution: TransactionSolution.wire,
      settlementPriority: TransactionSettlementPriority.sameDay,
    },
  ],
};

export const TRANSACTION_TYPE_OPTIONS = [
  SEND_MONEY_OPTION,
  REQUEST_MONEY_OPTION,
];

export const TRANSACTION_SOLUTION_OPTIONS = [
  ACH_OPTION,
  // PUSH_TO_CARD_OPTION,
  TRANSFER_OPTION,
  WIRE_OPTION,
];

export const achEntryTypeCodes: AchEntryType[] = [
  {
    value: 'ARC',
    label: 'label.ARC',
    types: ['pull'],
    // amountLimit: 25000,
  },
  {
    value: 'BOC',
    label: 'label.BOC',
    types: ['pull'],
    // amountLimit: 25000,
  },
  { value: 'CCD', label: 'label.CCD', types: ['pull', 'push'] },
  { value: 'CIE', label: 'label.CIE', types: ['push'] },
  { value: 'CTX', label: 'label.CTX', types: ['pull', 'push'] },
  { value: 'POP', label: 'label.POP', types: ['pull'] },
  { value: 'PPD', label: 'label.PPD', types: ['pull', 'push'] },
  { value: 'RCK', label: 'label.RCK', types: ['pull'] },
  { value: 'TEL', label: 'label.TEL', types: ['pull'] },
  { value: 'WEB', label: 'label.WEB', types: ['pull', 'push'] },
];
