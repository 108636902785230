import { Pagination } from '@mui/material';
import { isEqual } from 'lodash';
import { ChangeEvent, FC, useEffect } from 'react';

import EmptySearchResults from 'components/EmptySearchResults';
import ResponsiveTable from 'components/Table';
import { TableDivider, TablePaper } from 'components/Table/ResponsiveTable/index.styled';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { accountsSelector } from 'store/bank-accounts/selectors';
import {
  setAdvancedSearchSort,
  setAdvancedSearchPagination,
  fetchAdvancedTransactionSearch,
} from 'store/cash-flow/cashFlowSlice';
import {
  advancedSearchPaginationSelector,
  advancedSearchSearchSelector,
  advancedSearchSortOptionsSelector,
  advancedTransactionsSelector,
  isLoadingAdvancedSearchSelector,
} from 'store/cash-flow/selectors';

import { DefaultType } from 'constants/enums';
import { DEFAULT_PAGINATION_SIZE } from 'constants/general';
import { ADVANCED_TRANSACTION_SEARCH_COLUMNS } from 'constants/tableColumns';
import { GenericOption } from 'models/option.interface';
import { formatFiltersForServer } from 'modules/insights-advanced-search/utils';
import { getRequestSortOptions } from 'utils/sort';
import { AdvancedTransactionFilterSchema } from 'utils/validation/transactionFormSchema';

interface TransactionsProps {
  selectedAccounts: GenericOption[];
  filters: AdvancedTransactionFilterSchema;
}

const Transactions: FC<TransactionsProps> = ({ selectedAccounts, filters }) => {
  const dispatch = useAppDispatch();
  const { data: bankAccounts } = useAppSelector(accountsSelector, isEqual);
  const transactions = useAppSelector(advancedTransactionsSelector, isEqual);
  const isLoading = useAppSelector(isLoadingAdvancedSearchSelector);
  const search = useAppSelector(advancedSearchSearchSelector);
  const sortOptions = useAppSelector(advancedSearchSortOptionsSelector);
  const pagination = useAppSelector(advancedSearchPaginationSelector);
  const { totalPages, totalElements, page } = pagination;
  const hasPagination = totalElements > DEFAULT_PAGINATION_SIZE;

  useEffect(() => {
    fetchTransactions(page);
  }, [search, sortOptions, filters, selectedAccounts]);

  const fetchTransactions = (newPage: number) => {
    const hasAllOptionSelected = selectedAccounts.find(({ value }) => value === DefaultType.all);
    const payload = {
      page: newPage - 1,
      size: DEFAULT_PAGINATION_SIZE,
      sort: `${sortOptions.name},${sortOptions.type}`,
      bankAccountIds: hasAllOptionSelected
        ? bankAccounts?.map(({ id }) => id) || []
        : selectedAccounts.map(({ value }) => value),
      ...(search ? { search } : {}),
      ...formatFiltersForServer(filters),
    };
    dispatch(fetchAdvancedTransactionSearch(payload));
  };

  const handleChangePage = (event: ChangeEvent<unknown>, newPage: number) => {
    dispatch(setAdvancedSearchPagination({ page: newPage, totalPages, totalElements }));
    fetchTransactions(newPage);
  };

  const handleSort = (columnName: string) => {
    const newSortOption = getRequestSortOptions(columnName, sortOptions);
    dispatch(setAdvancedSearchSort(newSortOption));
  };

  return (
    <TablePaper elevation={0}>
      <ResponsiveTable
        id="table-transactions"
        columns={ADVANCED_TRANSACTION_SEARCH_COLUMNS}
        data={transactions}
        isRounded
        isLoading={isLoading}
        sortOptions={sortOptions}
        onSort={handleSort}
      />

      {hasPagination && (
        <>
          <TableDivider />
          <Pagination count={totalPages} page={page} onChange={handleChangePage} sx={{ my: 5 }} />
        </>
      )}

      {!transactions?.length && !isLoading && <EmptySearchResults />}
    </TablePaper>
  );
};

export default Transactions;
