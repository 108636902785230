/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const OutlineArrowDownIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14" cy="14" r="13.25" transform="rotate(90 14 14)" fill="white" stroke={color} strokeWidth="1.5" />
    <rect width="16.8" height="16.8" transform="matrix(-4.37114e-08 1 1 4.37114e-08 5.59961 5.59961)" fill="white" />
    <path
      d="M13.2989 8.39961L13.2989 16.9183L9.38636 13.0054L8.39941 13.9992L13.9994 19.5996L19.5994 13.9992L18.6125 13.0122L14.6991 16.9183L14.6991 8.39961L13.2989 8.39961Z"
      fill={color}
    />
  </svg>
);

export default OutlineArrowDownIcon;
