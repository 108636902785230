import {
  Box, CircularProgressProps, SxProps,
} from '@mui/material';
import { FC } from 'react';

import { CircularProgress } from './index.styled';

interface GradientStop {
  offset: string;
  color: string;
  opacity?: number;
}

interface GradientCircularProgressProps extends CircularProgressProps{
  gradientId: string;
  gradientStops: GradientStop[];
  sx?: SxProps;
}

const GradientCircularProgress: FC<GradientCircularProgressProps> = ({
  gradientId,
  gradientStops,
  sx = {},
  size = 290,
  thickness = 2,
  ...remainingProps
}) => (
  <Box sx={sx}>
    <svg width={0} height={0}>
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
          {gradientStops.map((stop, index) => (
            <stop
              key={`${gradientId}-${index}`}
              offset={stop.offset}
              stopColor={stop.color}
              stopOpacity={stop.opacity || 1}
            />
          ))}
        </linearGradient>
      </defs>
    </svg>
    <CircularProgress
      {...remainingProps}
      size={size}
      thickness={thickness}
      gradientId={gradientId}
    />
  </Box>
);

export default GradientCircularProgress;
