import { Box, FormLabel, Typography } from '@mui/material';
import { get } from 'lodash';
import { ReactElement, ReactNode } from 'react';
import {
  Control, Controller, FieldErrors, FieldValues, Path, UseFormRegister, UseFormWatch,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import DatePickerField from 'fields/DatePickerField';
import DropdownField from 'fields/DropdownField';

import { DateType } from 'constants/enums';
import {
  DATE_RANGE,
  DATE_RANGE_TYPE, FILTER_DATE_FROM, FILTER_DATE_TO,
} from 'constants/fields';
import { DATE_TYPE_OPTIONS } from 'constants/options';
import { getTranslatedErrors } from 'utils/errorUtils';

interface DateFormProps<FDate extends FieldValues> {
  control: Control<FDate>;
  watch: UseFormWatch<FDate>;
  register: UseFormRegister<FDate>;
  errors: Partial<FieldErrors<FDate>>;
  required?: boolean;
}

const DateForm = <FDate extends FieldValues>({
  register,
  errors,
  control,
  watch,
  required = false,
}: DateFormProps<FDate>) => {
  const intl = useIntl();
  const translatedErrors = getTranslatedErrors(errors);

  const dateRangeType = watch(DATE_RANGE_TYPE.name as Path<FDate>);
  const isCustom = dateRangeType === DateType.custom;
  const minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getTime();
  const maxDate = new Date().setHours(23, 59, 59, 59);
  const rootError = get(errors, DATE_RANGE.name)?.message as string;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <FormLabel required={required} sx={{ display: 'flex' }}>
        <Typography variant="subtitle1">
          {intl.formatMessage({ id: 'label.date' })}
        </Typography>
      </FormLabel>

      <Controller
        name={DATE_RANGE_TYPE.name as Path<FDate>}
        control={control}
        render={({ field }): ReactElement => (
          <DropdownField
            {...DATE_RANGE_TYPE}
            value={field?.value}
            options={DATE_TYPE_OPTIONS}
            inputProps={register(DATE_RANGE_TYPE.name as Path<FDate>)}
            error={!!translatedErrors?.[DATE_RANGE_TYPE.name]?.message}
            helperText={translatedErrors?.[DATE_RANGE_TYPE.name]?.message as ReactNode}
          />
        )}
      />

      {isCustom && (
        <Box display="flex" flexDirection="column" gap={2} ml={5}>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="body1" minWidth="60px">
              {intl.formatMessage({ id: FILTER_DATE_FROM.label })}
            </Typography>
            <Controller
              name={FILTER_DATE_FROM.name as Path<FDate>}
              control={control}
              render={({ field: { onChange, value } }): ReactElement => (
                <DatePickerField
                  {...FILTER_DATE_FROM}
                  value={value}
                  label=""
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={onChange}
                  variant="outlined"
                  error={!!translatedErrors?.[FILTER_DATE_FROM.name]?.message}
                  helperText={translatedErrors?.[FILTER_DATE_FROM.name]?.message as ReactNode}
                />
              )}
            />
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="body1" minWidth="60px">
              {intl.formatMessage({ id: FILTER_DATE_TO.label })}
            </Typography>
            <Controller
              name={FILTER_DATE_TO.name as Path<FDate>}
              control={control}
              render={({ field: { onChange, value } }): ReactElement => (
                <DatePickerField
                  {...FILTER_DATE_TO}
                  value={value}
                  label=""
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={onChange}
                  variant="outlined"
                  error={!!translatedErrors?.[FILTER_DATE_TO.name]?.message}
                  helperText={translatedErrors?.[FILTER_DATE_TO.name]?.message as ReactNode}
                />
              )}
            />
          </Box>

          {rootError && (
            <Typography variant="label1" color="general.darkRed1">
              {intl.formatMessage({ id: rootError })}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default DateForm;
