import { KybStatus } from 'constants/enums';

export const getCustomers = async () => Promise.resolve({
  content: [
    {
      id: 'vS9f5ObcOXMSifOUauv3A9tEgJQ2',
      email: 'alina+2@primaryaccount.com',
      name: 'Alina Test 2',
      businessName: 'Test Workspace 2',
      createdAt: 1694338094043,
      updatedAt: 1694338094909,
      photoUrl: '',
      phoneNumber: null,
      lastName: 'Test',
      firstName: 'Alina',
      address: {
        addressLine1: 'Bergen Street',
        city: 'Harrison',
        state: 'NJ',
        countryCode: 'USA',
        zipCode: '07029',
      },
      workspaces: [
        {
          id: '119427',
          name: 'PrimaryAccount',
          displayName: 'test-workspace-2',
          kybStatus: KybStatus.pending,
        },
      ],
    },
    {
      id: 'wpoKgkQd7aaAYu25wVFe02LL3qq1',
      email: 'alina+1@primaryaccount.com',
      name: 'Alina Test1',
      businessName: 'Test Workspace 1',
      createdAt: 1694337829121,
      updatedAt: 1694338724363,
      photoUrl: '',
      phoneNumber: null,
      lastName: 'Test1',
      firstName: 'Alina',
      address: {
        addressLine1: 'Bergen Street',
        city: 'Harrison',
        state: 'NJ',
        countryCode: 'USA',
        zipCode: '07029',
      },
      workspaces: [
        {
          id: '179345',
          name: 'PrimaryAccount',
          displayName: 'test-workspace-1',
          kybStatus: KybStatus.pending,
        },
      ],
    },
  ],
  totalPages: 1,
  totalElements: 1,
  pageable: { pageNumber: 0 },
});

export const getCustomerDetails = async (customerId: string) => {
  const { content } = await getCustomers();
  return Promise.resolve({
    data: content.find(({ id }) => id === customerId),
  });
};
