import { runGetApiRequest, runPostApiRequest } from 'services/Api';

import { TRANSACTION_BATCHES_API, CREATE_BATCHES_API } from 'constants/apiRoutes';
import { formatRoute } from 'utils/formatters';

export const getTransactionBatches = (businessAccountId: string, params: { [key: string]: string | number }) => (
  runGetApiRequest(formatRoute(TRANSACTION_BATCHES_API, { businessAccountId }), params)
);

export const createBatchTransactions = (data: any) => runPostApiRequest(CREATE_BATCHES_API, data);
