import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import DepositArrowIcon from 'assets/icons/DepositArrowIcon';
import WithdrawArrowIcon from 'assets/icons/WithdrawArrowIcon';
import { formatPrice } from 'utils/formatters';

import { Skeleton } from './index.styled';

interface TotalVolumeSectionProps {
  volume: number;
  amount: number;
  isLoading: boolean;
  isCredit?: boolean;
}

const TotalVolumeSection: FC<TotalVolumeSectionProps> = ({
  isLoading,
  volume,
  amount,
  isCredit = false,
}) => {
  const intl = useIntl();
  const formattedAmount = formatPrice({ price: amount });
  const IconComponent = isCredit ? DepositArrowIcon : WithdrawArrowIcon;

  return (
    <div>
      <Box display="flex" alignItems="center" justifyContent="space-between" gap={5}>
        <Box display="flex" alignItems="center" gap={2}>
          <IconComponent size={20} />

          <Typography variant="body2" color="common.white" fontWeight={700}>
            {intl.formatMessage({ id: isCredit ? 'label.totalCreditsVolume' : 'label.totalDebitsVolume' })}
          </Typography>
        </Box>

        {isLoading
          ? <Skeleton animation="wave" variant="rectangular" height={33} width={60} />
          : (
            <Typography variant="h1" color="common.white" fontSize="32px">
              {volume}
            </Typography>
          )}
      </Box>

      <Box display="flex" justifyContent="flex-end">
        {isLoading
          ? <Skeleton animation="wave" variant="rectangular" height={20} width={74} sx={{ mt: 3 }} />
          : (
            <Typography variant="body2" color="common.white" mt={3}>
              {isCredit ? formattedAmount : `-${formattedAmount}`}
            </Typography>
          )}
      </Box>
    </div>
  );
};

export default TotalVolumeSection;
