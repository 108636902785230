import { alpha, styled } from '@mui/material/styles';

import ChartGraph from 'assets/images/chart-graph.svg';

export const Root = styled('div')(() => ({
  position: 'relative',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '350px',
  height: '300px',
}));

export const BackgroundImage = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(10),
  marginTop: theme.spacing(20),
  height: '220px',
  width: '350px',
  backgroundImage: `url(${ChartGraph})`,
  backgroundPosition: 'center',
}));

export const BackgroundCircle = styled('div')(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.white, 0.18),
  width: '220px',
  height: '220px',
  borderRadius: '50%',
  backdropFilter: 'blur(4px)',
}));
