import {
  CurrencySymbol, CurrencyType,
} from 'constants/enums';

export const CONTACT_SALES_URL = 'https://primaryaccount.com/#contact';
export const ADMIN_PORTAL = 'Admin Portal';
export const MINI_ADMIN_PORTAL = 'Admin';
export const DEFAULT_LANGUAGE = 'en-US';
export const NAVBAR_HEIGHT = 60;
export const ACT_AS_BLOCK = 80;
export const SIDEBAR_WIDTH = {
  default: 248,
  small: 200,
  mini: 80,
};

export const SIDE_NAV_WIDTH = {
  default: 366,
  small: 300,
};

export const currencySymbolByCurrencyLabel = {
  [CurrencyType.usd]: CurrencySymbol.usd,
};

export const DEFAULT_PAGINATION_SIZE = 10;
export const RULE_HISTORY_PAGINATION_SIZE = 10;
export const TRANSACTION_HISTORY_PAGINATION_SIZE = 25;

export const REFRESH_ACCOUNTS_TIME = 5;
export const AUTO_REFRESH_ACCOUNTS_TIME = 15;

export const ONE_HUNDRED_THOUSAND = 100000;
export const ONE_MILLION = 1000000;
export const ONE_BILLION = 1000000000;

export const GENERAL_TOAST_OPTIONS = {
  delay: 1500,
};

export const NOT_APPLICABLE = '--';

export const ACCEPTED_IMAGE_TYPES = {
  'image/jpeg': ['.jpeg'],
  'image/png': ['.png'],
  'image/jpg': ['.jpg'],
  'image/webp': ['.webp'],
  'image/bmp': ['.bmp'],
};

export const ACCEPTED_COMPANY_DOCUMENT_TYPES = {
  'image/jpeg': ['.jpeg'],
  'image/png': ['.png'],
  'image/jpg': ['.jpg'],
  'application/pdf': ['.pdf'],
};

export const MEGABYTES_UPLOAD_LIMIT = 30;
export const TABLET_WIDTH = '768px';

export const MAX_ALERT_MESSAGE = 480;
export const MAX_MEMO_MESSAGE = 1024;
export const REFERRAL_CODES = ['SIGNUP-NATE-LIND', 'SIGNUP-2024'];

export const MAX_NICKNAME_LENGTH = 100;

export const TRANSACTION_SEARCH_SKELETON = 5;
