import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getHours, getMinutes, isPast,
} from 'date-fns';
import { isNil } from 'lodash';

import { resetStore } from 'store/actions';

import {
  AmPm,
  CustomMonthlyRecurrence,
  RecurrenceEndPeriod,
  TransactionRecurrence,
} from 'constants/enums';
import { CustomRecurrence } from 'models/recurrence.interface';
import { ScheduleTime } from 'models/scheduleTime.interface';
import { ScheduledTransaction } from 'models/transaction.interface';
import { formatScheduleTime } from 'utils/formatters';
import { getRecurrenceCustomRuleForForm, getRecurrenceRuleForForm } from 'utils/scheduleTime';

export interface ScheduleTimeState {
  scheduleTime: ScheduleTime;
  customRecurrence: CustomRecurrence;
  customRecurrenceErrors: Record<string, string>;
  hasScheduleTime: boolean;
  fieldErrors: Record<string, string>;
}

export const initialState: ScheduleTimeState = {
  hasScheduleTime: false,
  scheduleTime: {
    name: '',
    date: new Date().getTime(),
    hour: '',
    minutes: '',
    ampm: AmPm.am,
    recurrence: TransactionRecurrence.doesNotRepeat,
  },
  customRecurrence: {
    count: 1,
    repeatPeriod: TransactionRecurrence.weekly,
    repeatPeriodMonthlyType: CustomMonthlyRecurrence.monthlyOnCurrentDay,
    endsPeriod: RecurrenceEndPeriod.never,
    occurrences: 0,
    endDate: null,
  },
  fieldErrors: {},
  customRecurrenceErrors: {},
};

export const scheduleTimeSlice = createSlice({
  name: 'scheduleTime',
  initialState,
  reducers: {
    setScheduleTime: (state, action: PayloadAction<ScheduleTime>) => {
      state.scheduleTime = action.payload;

      const finalDate = formatScheduleTime(action.payload);
      const hasDateError = !!state.fieldErrors?.scheduleDate;

      if (!isNil(finalDate) && isPast(new Date(finalDate))) {
        state.fieldErrors = {
          ...state.fieldErrors,
          scheduleDate: 'error.dateIsInThePast',
        };
      } else if (hasDateError) {
        state.fieldErrors = {
          ...state.fieldErrors,
          scheduleDate: '',
        };
      }

      return state;
    },
    setFieldErrors: (state, action: PayloadAction<Record<string, string>>) => {
      state.fieldErrors = action.payload;
      return state;
    },
    setHasScheduleTime: (state, action: PayloadAction<boolean>) => {
      state.hasScheduleTime = action.payload;
      return state;
    },
    initScheduledEditMode: (state, action:PayloadAction<ScheduledTransaction>) => {
      let newState = {
        ...state,
        hasScheduleTime: !!action.payload.startDateTime,
      };

      if (action.payload.startDateTime) {
        const scheduleTime = new Date(`${action.payload.startDateTime}Z`).getTime();
        const hours = getHours(scheduleTime);
        const minutes = getMinutes(scheduleTime);
        const recurrence = action.payload.recurrenceRule
          ? getRecurrenceRuleForForm(action.payload.startDateTime, action.payload.recurrenceRule)
          : TransactionRecurrence.doesNotRepeat;

        newState = {
          ...newState,
          scheduleTime: {
            name: action.payload.name || '',
            date: scheduleTime,
            hour: hours > 12 ? (hours - 12).toString() : hours.toString(),
            minutes: minutes < 10 ? `0${minutes}` : minutes.toString(),
            ampm: hours > 12 ? AmPm.pm : AmPm.am,
            recurrence,
          },
          customRecurrence: action.payload.recurrenceRule && recurrence === TransactionRecurrence.customCreated
            ? getRecurrenceCustomRuleForForm(action.payload.recurrenceRule)
            : initialState.customRecurrence,
        };
      }

      return newState as ScheduleTimeState;
    },
    setCustomRecurrence: (state, action: PayloadAction<CustomRecurrence>) => {
      state.customRecurrence = action.payload;
      return state;
    },
    setCustomRecurrenceErrors: (state, action: PayloadAction<Record<string, string>>) => {
      state.customRecurrenceErrors = action.payload;
      return state;
    },
    resetCustomRecurrence: (state) => ({
      ...state,
      customRecurrence: initialState.customRecurrence,
    }),
    resetScheduleTime: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState);
  },
});

export const {
  setFieldErrors,
  setHasScheduleTime,
  setScheduleTime,
  initScheduledEditMode,
  resetCustomRecurrence,
  setCustomRecurrence,
  setCustomRecurrenceErrors,
  resetScheduleTime,
} = scheduleTimeSlice.actions;

export default scheduleTimeSlice.reducer;
