import {
  Box, Skeleton, Typography, Grid2 as Grid,
} from '@mui/material';
import { isEqual } from 'lodash';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import ActivityLegendItem from 'components/ActivityLegendItem';
import ActivityRankedChart from 'containers/ActivityRankedChart';

import { useAppSelector } from 'hooks/useRedux';
import { accountsSelector } from 'store/bank-accounts/selectors';

import { DateFormats, DefaultType } from 'constants/enums';
import { GenericOption } from 'models/option.interface';
import {
  CREDITS_CHART_LABEL,
  DEBITS_CHART_LABEL,
  TOTAL_TRANSACTIONS_CHART_LABEL,
} from 'utils/activityChartConfig';
import { getBankLogo } from 'utils/bank';
import { formatAcctNumberAndName, formatDate } from 'utils/formatters';

import { BankImg } from './index.styled';

interface AccountsActivityProps {
  selectedAccounts: GenericOption[];
  isLoading: boolean;
}

const AccountsActivity: FC<AccountsActivityProps> = ({
  selectedAccounts,
  isLoading,
}) => {
  const intl = useIntl();
  const { data: bankAccounts } = useAppSelector(accountsSelector, isEqual);

  const updatedAt = Date.now(); // TODO: change this;
  const formattedDate = updatedAt ? formatDate(updatedAt, DateFormats.detailedDateTime) : '';

  const displayAccounts = useMemo(() => {
    const hasAll = selectedAccounts?.some(({ value }) => value === DefaultType.all);

    if (hasAll) {
      return bankAccounts; // TODO: we need the top 5 accounts
    }

    const ids = selectedAccounts?.map((acc) => acc?.value).filter((value) => value);
    return ids?.length > 0
      ? bankAccounts?.filter(({ id }) => ids.includes(id)) || []
      : bankAccounts; // TODO: we need the top 5 accounts
  }, [bankAccounts, selectedAccounts]);

  const mockChartData = useMemo(() => (
    displayAccounts
      ? displayAccounts?.map(({ accountNumber, id, institution }) => ({
        accountNumber,
        accountId: id,
        institutionCode: institution?.code || '',
        institutionName: institution?.name || '',
        institutionLogo: institution?.smallLogoUrl || null,
        totalBalance: 0,
        totalNumberTransactions: 0,
        totalNumberDebits: 0,
        totalNumberCredits: 0,
        totalAmountDebits: 0,
        totalAmountCredits: 0,
        totalAmount: 0,
      }))
      : []
  ), [displayAccounts]); // TODO: Change this with the API response

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="subtitle1" fontWeight={700} color="textSecondary">
          {intl.formatMessage({ id: 'label.accountsActivity' })}
        </Typography>
        <Typography variant="body3" color="general.darkGrey1">
          {intl.formatMessage({ id: 'label.graphShowsSelected' })}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent={updatedAt ? 'space-between' : 'flex-end'}>
        {isLoading
          ? <Skeleton animation="wave" variant="rectangular" height={16} width={200} />
          : (updatedAt && (
            <Typography variant="body3" color="general.darkGrey1">
              {intl.formatMessage({ id: 'label.lastUpdatedAt' }, { updatedAt: formattedDate })}
            </Typography>
          ))}
        <Box display="flex" alignItems="center" gap={3}>
          <ActivityLegendItem
            legendItem={TOTAL_TRANSACTIONS_CHART_LABEL}
            label={intl.formatMessage({ id: TOTAL_TRANSACTIONS_CHART_LABEL.label })}
          />
          <ActivityLegendItem
            legendItem={CREDITS_CHART_LABEL}
            label={intl.formatMessage({ id: CREDITS_CHART_LABEL.label })}
          />
          <ActivityLegendItem
            legendItem={DEBITS_CHART_LABEL}
            label={intl.formatMessage({ id: DEBITS_CHART_LABEL.label })}
          />
        </Box>
      </Box>
      <Grid container mt={6} columnSpacing={5}>
        <Grid size={{ xs: 6 }}>
          <Box display="flex" flexDirection="column" gap={4}>
            {displayAccounts?.map((account) => {
              const name = formatAcctNumberAndName({
                accountNumber: account?.accountNumber,
                accountName: account?.name,
                isAccountWrapped: true,
              });

              return (
                <Box display="flex" alignItems="center" gap={1} key={account?.id}>
                  <BankImg src={getBankLogo(account?.institution)} alt={name} />
                  <Typography variant="label1">
                    {name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Grid>
        <Grid size={{ xs: 6 }}>
          {isLoading
            ? <Skeleton animation="wave" variant="rectangular" height={157} width="100%" />
            : <ActivityRankedChart data={mockChartData} />}
        </Grid>
      </Grid>
    </>
  );
};

export default AccountsActivity;
