import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

import { getMostActiveBankAccounts } from 'services/BankAccountService';
import { resetStore } from 'store/actions';

import { ApiStatus } from 'constants/enums';
import { BankAccount } from 'models/bankAccount.interface';
import { handleApiErrors } from 'utils/errorUtils';

export interface MostActiveBankAccountsState {
  accounts: {
    data: BankAccount[] | null;
    status: ApiStatus;
  };
}

const initialState:MostActiveBankAccountsState = {
  accounts: {
    data: null,
    status: ApiStatus.idle,
  },
};

export const fetchMostActiveBankAccounts = createAsyncThunk(
  'mostActiveBankAccounts/fetchMostActiveBankAccounts',
  async (workspaceId: string, thunkAPI) => {
    try {
      const response = await getMostActiveBankAccounts(workspaceId);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const mostActiveBankAccountsSlice = createSlice({
  name: 'mostActiveBankAccountsSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchMostActiveBankAccounts.pending, (state) => {
        state.accounts = {
          ...state.accounts,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchMostActiveBankAccounts.fulfilled, (state, action) => {
        if (!isEqual(state.accounts.data, action.payload)) {
          state.accounts = {
            ...state.accounts,
            status: ApiStatus.idle,
            data: action.payload,
          };
        }

        state.accounts.status = ApiStatus.idle;
      })
      .addCase(fetchMostActiveBankAccounts.rejected, (state) => {
        state.accounts = {
          ...state.accounts,
          status: ApiStatus.idle,
        };
      });
  },
});

export default mostActiveBankAccountsSlice.reducer;
