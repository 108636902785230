import { ChartCategoryType } from 'constants/enums';
import { ChartCategorySettings } from 'models/charts.interface';

export const TOTAL_BALANCE_CHART_LABEL = {
  id: ChartCategoryType.totalBalance,
  primaryColor: 'primary.main',
  label: 'label.totalBalance',
};

export const AVAILABLE_BALANCE_CHART_LABEL = {
  id: ChartCategoryType.availableBalance,
  primaryColor: 'secondary.dark',
  label: 'label.accountBalance',
};

export const INFLOW_CHART_LABEL = {
  id: ChartCategoryType.inflow,
  primaryColor: 'primary.main',
  label: 'label.credit',
};

export const OUTFLOW_CHART_LABEL = {
  id: ChartCategoryType.outflow,
  primaryColor: 'general.lightRed1',
  label: 'label.debit',
};

export const ACCOUNT_BALANCE_CHART_LABEL = {
  id: ChartCategoryType.accountBalance,
  primaryColor: 'primary.main',
  label: 'label.accountBalance',
};

export const TOTAL_TRANSACTIONS_CHART_LABEL = {
  id: ChartCategoryType.totalTransactions,
  isLine: true,
  primaryColor: 'general.lightBlack',
  label: 'label.totalTransactions',
};

export const CREDITS_CHART_LABEL = {
  id: ChartCategoryType.accountCredits,
  primaryColor: 'primary.main',
  label: 'label.credits',
  fieldName1: 'totalNumberCredits',
  fieldName2: 'totalAmountCredits',
};

export const DEBITS_CHART_LABEL = {
  id: ChartCategoryType.accountDebits,
  primaryColor: 'general.lightRed1',
  label: 'label.debits',
  fieldName1: 'totalNumberDebits',
  fieldName2: 'totalAmountDebits',
};

export const ACTIVITY_RANKED_CHART_SETTINGS : ChartCategorySettings[] = [
  CREDITS_CHART_LABEL,
  DEBITS_CHART_LABEL,
];

export const DETAILS_ACTIVITY_CHART_SETTINGS : ChartCategorySettings[] = [
  AVAILABLE_BALANCE_CHART_LABEL,
  INFLOW_CHART_LABEL,
  OUTFLOW_CHART_LABEL,
];
