import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import envConfig from 'configuration/env';

export const useDocumentTitle = ({ title }: { title: string}) => {
  const intl = useIntl();

  useEffect(() => {
    document.title = intl.formatMessage({ id: title });

    return () => {
      document.title = envConfig.brandName;
    };
  }, []);
};
