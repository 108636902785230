import { Box, Typography } from '@mui/material';

const Footer = () => (
  <Box pb={5} zIndex={1}>
    <Typography variant="body3" sx={{ opacity: '50%' }} color="common.white">
      © 2023 PrimaryAccount, Inc. All rights reserved.
    </Typography>
  </Box>
);

export default Footer;
