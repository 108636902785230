import { Box, Pagination } from '@mui/material';
import { isEqual } from 'lodash';
import { ChangeEvent, useEffect } from 'react';

import EmptySearchResults from 'components/EmptySearchResults';
import ResponsiveTable from 'components/Table';
import { TableDivider } from 'components/Table/ResponsiveTable/index.styled';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  fetchSummaryTransactions,
  setSummaryTransactionPagination,
  setSummaryTransactionSort,
} from 'store/cash-flow/cashFlowSlice';
import {
  isLoadingSummaryTransactionsSelector,
  summaryTransactionsPaginationSelector,
  summaryTransactionsSelector,
  summaryTransactionsSortOptionsSelector,
} from 'store/cash-flow/selectors';

import { CashFlowSearchType } from 'constants/enums';
import { DEFAULT_PAGINATION_SIZE } from 'constants/general';
import { INSIGHTS_SUMMARY_COLUMNS } from 'constants/tableColumns';
import { getRequestSortOptions } from 'utils/sort';

const SummaryTransactions = ({ filters }: any) => {
  const dispatch = useAppDispatch();
  const transactions = useAppSelector(summaryTransactionsSelector, isEqual);
  const isLoading = useAppSelector(isLoadingSummaryTransactionsSelector, isEqual);
  const sortOptions = useAppSelector(summaryTransactionsSortOptionsSelector, isEqual);
  const pagination = useAppSelector(summaryTransactionsPaginationSelector, isEqual);
  const hasPagination = pagination.totalElements > DEFAULT_PAGINATION_SIZE;

  useEffect(() => {
    dispatch(fetchSummaryTransactions({
      page: pagination.page - 1,
      size: DEFAULT_PAGINATION_SIZE,
      sort: `${sortOptions.name},${sortOptions.type}`,
      ...filters,
    }));
  }, [sortOptions, filters]);

  const handlePagination = (event: ChangeEvent<unknown>, newPage: number) => {
    dispatch(setSummaryTransactionPagination({ page: newPage }));
    dispatch(fetchSummaryTransactions({
      page: newPage - 1,
      size: DEFAULT_PAGINATION_SIZE,
      sort: `${sortOptions.name},${sortOptions.type}`,
      type: CashFlowSearchType.credit,
      ...filters,
    }));
  };

  const handleSort = (columnName: string) => {
    const newSortOption = getRequestSortOptions(columnName, sortOptions);
    dispatch(setSummaryTransactionSort(newSortOption));
  };

  return (
    <Box mt={4}>
      <ResponsiveTable
        isFullWidth
        id="table-transactions"
        columns={INSIGHTS_SUMMARY_COLUMNS}
        data={transactions}
        isRounded
        isLoading={isLoading}
        sortOptions={sortOptions}
        onSort={handleSort}
      />

      {hasPagination && (
        <>
          <TableDivider />
          <Pagination
            count={pagination.totalPages}
            page={pagination.page}
            onChange={handlePagination}
            sx={{ my: 5 }}
          />
        </>
      )}

      {!transactions?.length && <EmptySearchResults />}
    </Box>
  );
};

export default SummaryTransactions;
