import {
  Skeleton, Typography, Grid2 as Grid, Box,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import ActivityChat from 'modules/insights-cashflow/containers/ActivityChart';
import { Paper, SmallLine } from 'modules/insights-cashflow/containers/Summary/index.styled';

import { useAppSelector } from 'hooks/useRedux';
import { getCashFlowChartInfo } from 'services/CashFlowService';
import { cashFlowDetailsSelector, isLoadingCashFlowDetailsSelector } from 'store/cash-flow/selectors';

import { DateFormats, DateType } from 'constants/enums';
import { DATE_TYPE_LABELS } from 'constants/labels';
import { CashFlowChatDetails } from 'models/cashFlow.interface';
import { formatDateVolumeCharts } from 'utils/chart';
import { handleApiErrors } from 'utils/errorUtils';
import { formatStringDate } from 'utils/formatters';

const Summary = ({ filters, bankAccountIds }: any) => {
  const intl = useIntl();
  const isLoading = useAppSelector(isLoadingCashFlowDetailsSelector);
  const cashFlowDetails = useAppSelector(cashFlowDetailsSelector);
  const [chartData, setChartData] = useState<CashFlowChatDetails|null>(null);
  const activityData = useMemo(() => (
    [chartData?.volumes ? formatDateVolumeCharts(chartData?.volumes) : [], []]
  ), [chartData]);
  const periodLabel = useMemo(() => {
    if (filters.interval) {
      const label = DATE_TYPE_LABELS[filters.interval as DateType];
      return intl.formatMessage({ id: label || 'label.customDateRange' });
    }

    const period = [];
    if (filters.dateFrom) {
      period.push(formatStringDate(filters.dateFrom, DateFormats.defaultDate));
    }

    if (filters.dateTo) {
      period.push(formatStringDate(filters.dateTo, DateFormats.defaultDate));
    }

    return period.join(' - ');
  }, [filters]);

  useEffect(() => {
    getChartDetails();
  }, [filters, bankAccountIds]);

  const getChartDetails = async () => {
    try {
      const { data } = await getCashFlowChartInfo({ ...filters, bankAccountIds });
      setChartData(data);
    } catch (e) {
      handleApiErrors(e);
    }
  };

  return (
    <Paper elevation={0} className="large">
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 12, md: 6 }}>
          <Typography variant="subtitle1" fontWeight={700} mb={3}>
            {intl.formatMessage({ id: 'label.allTransactionVolume' })}
          </Typography>
          {isLoading ? (
            <Skeleton animation="wave" variant="rectangular" height={30} width={80} />
          ) : (
            <Typography variant="h1">
              {cashFlowDetails?.totalVolume || 0}
            </Typography>
          )}

          <Box display="flex" alignItems="center" mt={5}>
            <SmallLine />

            <Typography variant="label1" mr={3} ml={2}>
              {periodLabel}
            </Typography>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 6 }}>
          {!isLoading && chartData?.volumes ? (
            <ActivityChat data={activityData} />
          ) : (
            <Skeleton animation="wave" variant="rectangular" height={150} width="100%" />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Summary;
