import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import GradientCircularProgress from 'modules/insights-summary/components/GradientCircularProgress';

import { Skeleton } from '../TotalVolumeSection/index.styled';
import { BackgroundCircle, BackgroundImage, Root } from './index.styled';

interface DonutChartProps {
  totalVolume: number;
  creditsVolume: number;
  isLoading?: boolean;
}

const DonutChart: FC<DonutChartProps> = ({
  creditsVolume,
  totalVolume,
  isLoading = false,
}) => {
  const intl = useIntl();
  const creditsPercentage = creditsVolume > 0
    ? (creditsVolume * 100) / totalVolume
    : 0;

  const gradientStops1 = [
    { offset: '49.09%', color: '#F16868' },
    { offset: '67.9%', color: '#F47E84', opacity: 0.9 },
    { offset: '86.72%', color: '#F894A1', opacity: 0.8 },
  ];

  const gradientStops2 = [
    { offset: '25.18%', color: '#52DB99' },
    { offset: '63.48%', color: '#139F54', opacity: 0.9 },
    { offset: '101.79%', color: '#27DE70', opacity: 0.8 },
  ];

  return (
    <Root>
      <GradientCircularProgress
        variant="determinate"
        value={100}
        gradientId="gradient1"
        gradientStops={gradientStops1}
        sx={{ position: 'absolute' }}
      />
      <GradientCircularProgress
        variant="determinate"
        value={creditsPercentage}
        gradientId="gradient2"
        gradientStops={gradientStops2}
        sx={{ position: 'absolute' }}
      />
      <BackgroundImage />
      <Box position="absolute" display="flex" justifyContent="center" alignItems="center">
        <BackgroundCircle />
        <Box position="absolute" display="flex" flexDirection="column" alignItems="center">
          <Typography variant="body3" color="common.white">
            {intl.formatMessage({ id: 'label.totalVolume' })}
          </Typography>
          {isLoading
            ? <Skeleton animation="wave" variant="rectangular" height={33} width={86} sx={{ mt: 5 }} />
            : (
              <Typography variant="h1" color="common.white" fontSize="48px" fontWeight={800} mt={5}>
                {totalVolume}
              </Typography>
            )}
        </Box>
      </Box>
    </Root>
  );
};

export default DonutChart;
