import ACHIcon from 'assets/icons/ACHIcon';
import ActivityIcon from 'assets/icons/ActivityIcon';
import AlertIcon from 'assets/icons/AlertIcon';
import ArchiveIcon from 'assets/icons/ArchiveIcon';
import ArrowDownIcon from 'assets/icons/ArrowDownIcon';
import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon';
import ArrowRightIcon from 'assets/icons/ArrowRightIcon';
import ArrowUpIcon from 'assets/icons/ArrowUpIcon';
import BankCardIcon from 'assets/icons/BankCardIcon';
import BankIcon from 'assets/icons/BankIcon';
import BarChartIcon from 'assets/icons/BarChartIcon';
import BatchesIcon from 'assets/icons/BatchesIcon';
import BeneficiaryIcon from 'assets/icons/BeneficiaryIcon';
import BillingIcon from 'assets/icons/BillingIcon';
import BrandIcon from 'assets/icons/BrandIcon';
import BusinessmanIcon from 'assets/icons/BusinessmanIcon';
import CalendarIcon from 'assets/icons/CalendarIcon';
import CardIcon from 'assets/icons/CardIcon';
import CelebrationIcon from 'assets/icons/CelebrationIcon';
import CheckIcon from 'assets/icons/CheckIcon';
import ChevronDownIcon from 'assets/icons/ChevronDownIcon';
import ChevronLeftIcon from 'assets/icons/ChevronLeftIcon';
import ChevronRightIcon from 'assets/icons/ChevronRightIcon';
import ChevronUpIcon from 'assets/icons/ChevronUpIcon';
import CloseIcon from 'assets/icons/CloseIcon';
import CompanyIcon from 'assets/icons/CompanyIcon';
import CopyIcon from 'assets/icons/CopyIcon';
import CreateAccountIcon from 'assets/icons/CreateAccountIcon';
import DashboardIcon from 'assets/icons/DashboardIcon';
import DashedCloud from 'assets/icons/DashedCloud';
import DeleteIcon from 'assets/icons/DeleteIcon';
import DepositArrowIcon from 'assets/icons/DepositArrowIcon';
import DialogIcon from 'assets/icons/DialogIcon';
import DocumentIcon from 'assets/icons/DocumentIcon';
import DollarIcon from 'assets/icons/DollarIcon';
import DoubleLeftIcon from 'assets/icons/DoubleLeftIcon';
import DoubleRightIcon from 'assets/icons/DoubleRightIcon';
import DownloadIcon from 'assets/icons/DownloadIcon';
import EditIcon from 'assets/icons/EditIcon';
import ErrorIcon from 'assets/icons/ErrorIcon';
import FilledUserIcon from 'assets/icons/FilledUserIcon';
import FilterIcon from 'assets/icons/FilterIcon';
import InfoIcon from 'assets/icons/InfoIcon';
import InsightIcon from 'assets/icons/InsightIcon';
import KeyIcon from 'assets/icons/KeyIcon';
import LinkAccountIcon from 'assets/icons/LinkAccountIcon';
import LinkIcon from 'assets/icons/LinkIcon';
import LinXRequestIcon from 'assets/icons/LinXRequestIcon';
import LoadingIcon from 'assets/icons/LoadingIcon';
import LocationIcon from 'assets/icons/LocationIcon';
import LockIcon from 'assets/icons/LockIcon';
import LogoutIcon from 'assets/icons/LogoutIcon';
import MailIcon from 'assets/icons/MailIcon';
import MembershipCardIcon from 'assets/icons/MembershipCardIcon';
import MoneyBagIcon from 'assets/icons/MoneyBagIcon';
import MonitorIcon from 'assets/icons/MonitorIcon';
import MoreIcon from 'assets/icons/MoreIcon';
import MoveMoneyIcon from 'assets/icons/MoveMoneyIcon';
import MyAccountIcon from 'assets/icons/MyAccountIcon';
import NoteIcon from 'assets/icons/NoteIcon';
import NotificationIcon from 'assets/icons/NotificationIcon';
import OutlineArrowDownIcon from 'assets/icons/OutlineArrowDownIcon';
import OutlineCheckIcon from 'assets/icons/OutlineCheckIcon';
import OutlinedCloseIcon from 'assets/icons/OutlinedCloseIcon';
import OutlinedPlusIcon from 'assets/icons/OutlinedPlusIcon';
import PaginationDotsIcon from 'assets/icons/PaginationDotsIcon';
import PhoneIcon from 'assets/icons/PhoneIcon';
import PlusBoxIcon from 'assets/icons/PlusBoxIcon';
import PlusIcon from 'assets/icons/PlusIcon';
import ProfileIcon from 'assets/icons/ProfileIcon';
import ReceiveMoney from 'assets/icons/ReceiveMoney';
import RefreshIcon from 'assets/icons/RefreshIcon';
import RemoveStarIcon from 'assets/icons/RemoveStarIcon';
import ResendIcon from 'assets/icons/ResendIcon';
import ResumeIcon from 'assets/icons/ResumeIcon';
import ReverseIcon from 'assets/icons/ReverseIcon';
import RoundedArrowDownIcon from 'assets/icons/RoundedArrowDownIcon';
import RoundedArrowUpIcon from 'assets/icons/RoundedArrowUpIcon';
import SadCloudIcon from 'assets/icons/SadCloudIcon';
import SameDayIcon from 'assets/icons/SameDayIcon';
import SaveIcon from 'assets/icons/SaveIcon';
import ScheduleBubbleIcon from 'assets/icons/ScheduleBubbleIcon';
import ScheduleIcon from 'assets/icons/ScheduleIcon';
import SearchFileIcon from 'assets/icons/SearchFileIcon';
import SearchIcon from 'assets/icons/SearchIcon';
import SecurityIcon from 'assets/icons/SecurityIcon';
import SendIcon from 'assets/icons/SendIcon';
import SettingsIcon from 'assets/icons/SettingsIcon';
import SmsIcon from 'assets/icons/SmsIcon';
import SortingIcon from 'assets/icons/SortingIcon';
import SpeakerIcon from 'assets/icons/SpeakerIcon';
import StarFilledIcon from 'assets/icons/StarFilledIcon';
import StarIcon from 'assets/icons/StarIcon';
import SubcontractIcon from 'assets/icons/SubcontractIcon';
import SuccessIcon from 'assets/icons/SuccessIcon';
import SuspendIcon from 'assets/icons/SuspendIcon';
import TeamIcon from 'assets/icons/TeamIcon';
import TransactionApprovalIcon from 'assets/icons/TransactionApprovalIcon';
import TransactionIcon from 'assets/icons/TransactionIcon';
import TransactionsIcon from 'assets/icons/TransactionsIcon';
import TransferIcon from 'assets/icons/TransferIcon';
import UnarchiveIcon from 'assets/icons/UnarchiveIcon';
import UnlinkIcon from 'assets/icons/UnlinkIcon';
import UnlockedIcon from 'assets/icons/UnlockedIcon';
import UploadIcon from 'assets/icons/UploadIcon';
import UserIcon from 'assets/icons/UserIcon';
import UsFlagIcon from 'assets/icons/UsFlagIcon';
import WithdrawArrowIcon from 'assets/icons/WithdrawArrowIcon';
import WorkspaceIcon from 'assets/icons/WorkspaceIcon';

export const icons = [
  { Icon: ACHIcon, name: 'ACHIcon' },
  { Icon: ActivityIcon, name: 'ActivityIcon' },
  { Icon: ArchiveIcon, name: 'ArchiveIcon' },
  { Icon: ArrowDownIcon, name: 'ArrowDownIcon' },
  { Icon: ArrowLeftIcon, name: 'ArrowLeftIcon' },
  { Icon: ArrowRightIcon, name: 'ArrowRightIcon' },
  { Icon: ArrowUpIcon, name: 'ArrowUpIcon' },
  { Icon: BankIcon, name: 'BankIcon' },
  { Icon: BarChartIcon, name: 'BarChartIcon' },
  { Icon: BatchesIcon, name: 'BatchesIcon' },
  { Icon: BrandIcon, name: 'BrandIcon' },
  { Icon: CalendarIcon, name: 'CalendarIcon' },
  { Icon: CardIcon, name: 'CardIcon' },
  { Icon: CheckIcon, name: 'CheckIcon' },
  { Icon: BusinessmanIcon, name: 'BusinessmanIcon' },
  { Icon: ChevronDownIcon, name: 'ChevronDownIcon' },
  { Icon: CreateAccountIcon, name: 'CreateAccountIcon' },
  { Icon: ChevronLeftIcon, name: 'ChevronLeftIcon' },
  { Icon: ChevronRightIcon, name: 'ChevronRightIcon' },
  { Icon: ChevronUpIcon, name: 'ChevronUpIcon' },
  { Icon: CloseIcon, name: 'CloseIcon' },
  { Icon: DashboardIcon, name: 'DashboardIcon' },
  { Icon: SubcontractIcon, name: 'SubcontractIcon' },
  { Icon: DashedCloud, name: 'DashedCloud' },
  { Icon: DeleteIcon, name: 'DeleteIcon' },
  { Icon: DepositArrowIcon, name: 'DepositArrowIcon' },
  { Icon: DocumentIcon, name: 'DocumentIcon' },
  { Icon: DollarIcon, name: 'DollarIcon' },
  { Icon: DoubleLeftIcon, name: 'DoubleLeftIcon' },
  { Icon: DoubleRightIcon, name: 'DoubleRightIcon' },
  { Icon: DownloadIcon, name: 'DownloadIcon' },
  { Icon: EditIcon, name: 'EditIcon' },
  { Icon: ErrorIcon, name: 'ErrorIcon' },
  { Icon: FilterIcon, name: 'FilterIcon' },
  { Icon: InfoIcon, name: 'InfoIcon' },
  { Icon: InsightIcon, name: 'InsightIcon' },
  { Icon: LinXRequestIcon, name: 'LinXRequestIcon' },
  { Icon: LoadingIcon, name: 'LoadingIcon' },
  { Icon: LocationIcon, name: 'LocationIcon' },
  { Icon: LockIcon, name: 'LockIcon' },
  { Icon: UnlockedIcon, name: 'UnlockedIcon' },
  { Icon: LogoutIcon, name: 'LogoutIcon' },
  { Icon: MailIcon, name: 'MailIcon' },
  { Icon: MoneyBagIcon, name: 'MoneyBagIcon' },
  { Icon: MonitorIcon, name: 'MonitorIcon' },
  { Icon: MoreIcon, name: 'MoreIcon' },
  { Icon: BankCardIcon, name: 'BankCardIcon' },
  { Icon: MoveMoneyIcon, name: 'MoveMoneyIcon' },
  { Icon: MyAccountIcon, name: 'MyAccountIcon' },
  { Icon: NoteIcon, name: 'NoteIcon' },
  { Icon: NotificationIcon, name: 'NotificationIcon' },
  { Icon: OutlineArrowDownIcon, name: 'OutlineArrowDownIcon' },
  { Icon: OutlinedCloseIcon, name: 'OutlinedCloseIcon' },
  { Icon: PaginationDotsIcon, name: 'PaginationDotsIcon' },
  { Icon: PhoneIcon, name: 'PhoneIcon' },
  { Icon: PlusBoxIcon, name: 'PlusBoxIcon' },
  { Icon: PlusIcon, name: 'PlusIcon' },
  { Icon: ProfileIcon, name: 'ProfileIcon' },
  { Icon: ReceiveMoney, name: 'ReceiveMoney' },
  { Icon: ResendIcon, name: 'ResendIcon' },
  { Icon: ResumeIcon, name: 'ResumeIcon' },
  { Icon: ReverseIcon, name: 'ReverseIcon' },
  { Icon: SadCloudIcon, name: 'SadCloudIcon' },
  { Icon: SameDayIcon, name: 'SameDayIcon' },
  { Icon: SaveIcon, name: 'SaveIcon' },
  { Icon: ScheduleIcon, name: 'ScheduleIcon' },
  { Icon: SearchFileIcon, name: 'SearchFileIcon' },
  { Icon: SearchIcon, name: 'SearchIcon' },
  { Icon: SendIcon, name: 'SendIcon' },
  { Icon: SettingsIcon, name: 'SettingsIcon' },
  { Icon: SpeakerIcon, name: 'SpeakerIcon' },
  { Icon: StarFilledIcon, name: 'StarFilledIcon' },
  { Icon: StarIcon, name: 'StarIcon' },
  { Icon: SuspendIcon, name: 'SuspendIcon' },
  { Icon: TeamIcon, name: 'TeamIcon' },
  { Icon: TransactionIcon, name: 'TransactionIcon' },
  { Icon: TransactionsIcon, name: 'TransactionsIcon' },
  { Icon: TransferIcon, name: 'TransferIcon' },
  { Icon: UserIcon, name: 'UserIcon' },
  { Icon: UsFlagIcon, name: 'UsFlagIcon' },
  { Icon: WithdrawArrowIcon, name: 'WithdrawArrowIcon' },
  { Icon: CompanyIcon, name: 'CompanyIcon' },
  { Icon: FilledUserIcon, name: 'FilledUserIcon' },
  { Icon: CopyIcon, name: 'CopyIcon' },
  { Icon: LinkIcon, name: 'LinkIcon' },
  { Icon: LinkAccountIcon, name: 'LinkAccountIcon' },
  { Icon: UnlinkIcon, name: 'UnlinkIcon' },
  { Icon: RefreshIcon, name: 'RefreshIcon' },
  { Icon: RemoveStarIcon, name: 'RemoveStarIcon' },
  { Icon: SortingIcon, name: 'SortingIcon' },
  { Icon: SecurityIcon, name: 'SecurityIcon' },
  { Icon: KeyIcon, name: 'KeyIcon' },
  { Icon: UploadIcon, name: 'UploadIcon' },
  { Icon: UnarchiveIcon, name: 'UnarchiveIcon' },
  { Icon: WorkspaceIcon, name: 'WorkspaceIcon' },
  { Icon: OutlinedPlusIcon, name: 'OutlinedPlusIcon' },
  { Icon: OutlineCheckIcon, name: 'OutlineCheckIcon' },
  { Icon: BeneficiaryIcon, name: 'BeneficiaryIcon' },
  { Icon: BillingIcon, name: 'BillingIcon' },
  { Icon: MembershipCardIcon, name: 'MembershipCardIcon' },
  { Icon: TransactionApprovalIcon, name: 'TransactionApprovalIcon' },
  { Icon: SmsIcon, name: 'SmsIcon' },
  { Icon: DialogIcon, name: 'DialogIcon' },
];

export const largeIcons = [
  { Icon: AlertIcon, name: 'AlertIcon' },
  { Icon: SuccessIcon, name: 'SuccessIcon' },
  { Icon: ScheduleBubbleIcon, name: 'ScheduleBubbleIcon' },
  { Icon: CelebrationIcon, name: 'CelebrationIcon' },
  { Icon: RoundedArrowDownIcon, name: 'RoundedArrowDownIcon' },
  { Icon: RoundedArrowUpIcon, name: 'RoundedArrowUpIcon' },
];
