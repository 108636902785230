import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import {
  Grid2 as Grid, Box, Typography, Skeleton,
} from '@mui/material';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import DateForm from 'components/DateForm';
import InsightsEmptyState from 'components/InsightsEmptyState';
import AdvancedAccountFilter from 'containers/AdvancedAccountFilter';
import AccountsFilter from 'containers/AdvancedAccountFilter/AccountsFilter';
import CreditTransactions from 'modules/insights-cashflow/containers/CreditTransactions';
import DebitTransactions from 'modules/insights-cashflow/containers/DebitTransactions';
import Summary from 'modules/insights-cashflow/containers/Summary';

import { useInsightsFilters } from 'hooks/useInsightsFilters';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { saveUserFilterPreferences } from 'services/UserService';
import { accountsSelector } from 'store/bank-accounts/selectors';
import { fetchCashFlowDetails, resetCashFlow } from 'store/cash-flow/cashFlowSlice';
import { cashFlowDetailsSelector, isLoadingCashFlowDetailsSelector } from 'store/cash-flow/selectors';
import { currentWorkspaceSelector, userSelector } from 'store/user/selectors';

import {
  DefaultType, InsightsComponents,
} from 'constants/enums';
import { GENERAL_TOAST_OPTIONS } from 'constants/general';
import { GenericOption } from 'models/option.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { formatPrice } from 'utils/formatters';
import {
  cashflowSchema,
  CashflowSchema,
} from 'utils/validation/cashflowSchema';

import { DEFAULT_FILTERS } from '../../constants';
import { formatFiltersForServer } from '../../utils';

const CashflowAnalysis = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(isLoadingCashFlowDetailsSelector);
  const cashFlowDetails = useAppSelector(cashFlowDetailsSelector);
  const { data: bankAccounts, isLoading: isLoadingAccounts } = useAppSelector(accountsSelector, isEqual);
  const currentWorkspace = useAppSelector(currentWorkspaceSelector);
  const loggedUser = useAppSelector(userSelector);
  const workspaceId = currentWorkspace?.id || '';

  const {
    tempAccounts,
    setTempAccounts,
    selectedAccounts,
    setSelectedAccounts,
    filters,
    setFilters,
    onResetAll,
    hasSavedFilters,
    setHasSavedFilters,
  } = useInsightsFilters<CashflowSchema>({
    defaultFilters: DEFAULT_FILTERS,
    pageName: InsightsComponents.cashflow,
  });
  const [expanded, setExpanded] = useState<boolean>(false);
  const formattedFilters = useMemo(() => (
    formatFiltersForServer(filters)
  ), [filters]);
  const bankAccountIds = useMemo(() => {
    const hasAllOptionSelected = selectedAccounts.find(({ value }) => value === DefaultType.all);
    return hasAllOptionSelected
      ? bankAccounts?.map(({ id }) => id) || []
      : selectedAccounts.map(({ value }) => value);
  }, [selectedAccounts, bankAccounts]);

  const {
    handleSubmit,
    register,
    control,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm<CashflowSchema>({
    resolver: zodResolver(cashflowSchema),
    mode: 'onSubmit',
    defaultValues: DEFAULT_FILTERS,
  });

  useEffect(() => {
    reset(filters);
  }, [filters]);

  useEffect(() => () => {
    dispatch(resetCashFlow());
  }, []);

  const isFormDirty = useMemo(() => {
    if (isDirty) {
      return true;
    }

    const newIds = tempAccounts?.map((acc) => acc?.value).filter((value) => value);
    return !isEqual(newIds, selectedAccounts);
  }, [isDirty, tempAccounts, selectedAccounts]);

  useEffect(() => {
    const queryParams = { ...formattedFilters, bankAccountIds };
    dispatch(fetchCashFlowDetails(queryParams));
  }, [formattedFilters, bankAccountIds]);

  const onSubmit = (newFilters: CashflowSchema) => {
    if (!isEqual(newFilters, filters)) {
      setFilters(newFilters);
      reset(newFilters);
    }

    const newSelectedAccounts = tempAccounts.filter((account) => account?.value) as GenericOption[];

    if (!isEqual(selectedAccounts, tempAccounts)) {
      setSelectedAccounts(newSelectedAccounts);
    }

    setExpanded(false);

    if (!loggedUser?.id) {
      toast.error(intl.formatMessage({ id: 'error.missingInfoPreferences' }), GENERAL_TOAST_OPTIONS);
      return;
    }

    try {
      const hasAllOptionSelected = newSelectedAccounts.find(({ value }) => value === DefaultType.all);
      const payloadFilters: Record<string, any> = {
        ...formatFiltersForServer(newFilters),
        bankAccountIds: hasAllOptionSelected
          ? bankAccounts?.map(({ id }) => id).join(',') || ''
          : newSelectedAccounts.map(({ value }) => value).join(','),
      };
      const payload = {
        pageName: InsightsComponents.cashflow,
        filter: Object.entries(payloadFilters)?.map(([element, value]) => ({ element, value })),
      };

      saveUserFilterPreferences(workspaceId, loggedUser?.id, payload);
      setHasSavedFilters(true);
    } catch (e) {
      handleApiErrors(e);
    }
  };

  const onCancel = () => {
    reset(filters);
    setTempAccounts(selectedAccounts);
    setExpanded(false);
  };

  const isSaveDisabled = !tempAccounts.filter((account) => account?.value)?.length
    || (isEqual(tempAccounts.filter((account) => account?.value), selectedAccounts) && !isFormDirty);

  if (!bankAccounts?.length) {
    return <InsightsEmptyState isLoading={isLoadingAccounts} />;
  }

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      <AdvancedAccountFilter
        accounts={selectedAccounts}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={onCancel}
        disabled={isSaveDisabled}
        isExpanded={expanded}
        onResetAll={onResetAll}
        onSetIsExpanded={setExpanded}
        filters={formattedFilters}
        hasResetAll={hasSavedFilters}
      >
        <Grid container>
          <Grid size={{ xs: 8 }}>
            <AccountsFilter
              hasAllOption
              selectedAccounts={tempAccounts}
              setSelectedAccounts={setTempAccounts}
            />
          </Grid>
          <Grid size={{ xs: 4 }}>
            <DateForm
              <CashflowSchema>
              register={register}
              watch={watch}
              control={control}
              errors={errors}
              required
            />
          </Grid>
        </Grid>
      </AdvancedAccountFilter>

      <Summary filters={formattedFilters} bankAccountIds={bankAccountIds} />

      <Box display="flex" justifyContent="space-between" width="100%">
        <Typography variant="h2">
          {intl.formatMessage({ id: 'label.consolidatedStartingAccountBalance' })}
        </Typography>
        {isLoading ? (
          <Skeleton animation="wave" variant="rectangular" height={40} width={100} />
        ) : (
          <Typography variant="h2">
            {formatPrice({ price: cashFlowDetails?.startBalance || 0 })}
          </Typography>
        )}
      </Box>

      <DebitTransactions filters={formattedFilters} bankAccountIds={bankAccountIds} />

      <CreditTransactions filters={formattedFilters} bankAccountIds={bankAccountIds} />

      <Box display="flex" justifyContent="space-between" width="100%">
        <Typography variant="h2">
          {intl.formatMessage({ id: 'label.consolidatedEndingAccountBalance' })}
        </Typography>
        {isLoading ? (
          <Skeleton animation="wave" variant="rectangular" height={40} width={100} />
        ) : (
          <Typography variant="h2">
            {formatPrice({ price: cashFlowDetails?.endBalance || 0 })}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CashflowAnalysis;
