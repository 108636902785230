import { alpha, styled } from '@mui/material/styles';
import { get } from 'lodash';

import { ChartCategorySettings } from 'models/charts.interface';

export const LegendSymbol = styled('div')<{
  legendItem: ChartCategorySettings;
}>(({
  theme,
  legendItem,
}) => ({
  height: legendItem.isLine ? '4px' : '12px',
  width: '12px',
  marginRight: theme.spacing(2),
  backgroundColor: legendItem.opacity
    ? alpha(get(theme.palette, legendItem.primaryColor), legendItem.opacity)
    : get(theme.palette, legendItem.primaryColor),
  borderRadius: legendItem.isLine ? '2px' : '4px',
  border: (legendItem.isDashed && legendItem.borderColor)
    ? `1px dashed ${get(theme.palette, legendItem.borderColor)}`
    : '',
}));

export const ItemWrapper = styled('div')<{ hasBorder: boolean }>(({ theme, hasBorder }) => ({
  borderLeft: hasBorder
    ? `1px solid ${theme.palette.general.darkGrey1}`
    : '',
  paddingLeft: theme.spacing(hasBorder ? 2 : 0),
  display: 'flex',
  alignItems: 'center',
}));
