import { styled } from '@mui/material/styles';

export const SearchWrapper = styled('div')(({ theme }) => ({
  width: 'fit-content',

  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.common.white,
  },

  '& .MuiFormControl-root': {
    width: '900px',

    [theme.breakpoints.down('lg')]: {
      width: '600px',
    },

    [theme.breakpoints.down('md')]: {
      width: '400px',
    },
  },
}));
