import { DateType } from 'constants/enums';

export const MAX_VISIBLE_ACCOUNTS = 3;
export const MAX_VISIBLE_ACCOUNTS_SUMMARY = 5;

export const DEFAULT_FILTERS = {
  dateRange: {
    type: DateType.sevenDays,
    from: null,
    to: null,
  },
};
