/* eslint-disable max-len */
export const FISERV_SESSION_ID_API = 'api/customers/:workspaceId/sessionId';
export const CHECK_ACCOUNT_UPDATE_COMPLETED_API = 'api/customers/:workspaceId/bank-accounts/external/checkAccountUpdateCompleted';
export const IDP_API = 'api/customers/:workspaceId/idp-accounts';
export const BANK_ACCOUNT_TRANSACTIONS_API = 'api/customers/:workspaceId/bank-transactions';
export const DELETE_BANK_ACCOUNT_API = 'api/customers/:bankAccountId/bank-accounts/:workspaceId';
export const BANK_ACCOUNTS_API = 'api/customers/:workspaceId/bank-accounts';
export const MOST_ACTIVE_BANK_ACCOUNTS_API = 'api/customers/:workspaceId/most-active-accounts';
export const EXTERNAL_BANK_ACCOUNTS_API = 'api/customers/:workspaceId/bank-accounts/external';
export const BANK_ACCOUNT_BY_ID_API = 'api/customers/:workspaceId/bank-accounts/:accountId';
export const BANK_ACCOUNT_DETAILS_API = 'api/customers/:workspaceId/bank-accounts/:accountId/details';
export const BACKGROUND_UPDATE_CUSTOMER_BANK_ACCOUNTS_API = 'api/customers/:workspaceId/bank-accounts/update';
export const BANK_ACCOUNT_UPDATE_API = 'api/customers/:workspaceId/bank-accounts/external/:bankAccountId/update';
export const CREATE_INTEGRATED_BANK_ACCOUNT_API = 'api/customers/:workspaceId/bank-accounts/integrated';
export const INFLOW_OUTFLOW_WIDGET_API = 'api/customers/:workspaceId/inflow-outflow-widget';
export const ACTIVITY_WIDGET_API = 'api/customers/:workspaceId/activity-widget';
export const TRANSACTION_ACTIVITY_WIDGET_API = 'api/customers/:workspaceId/transaction-activity-widget';
export const ACCOUNT_BALANCE_RANKING_WIDGET_API = 'api/customers/:workspaceId/account-balance-ranking-widget';
export const ACCOUNT_DETAILS_ACTIVITY_WIDGET_API = 'api/customers/:workspaceId/account-details-activity-widget';
export const COMPANY_LIMITS_API = 'api/customers/:workspaceId/limits';
export const COMPANY_UTILIZATION_LIMITS_API = 'api/customers/:workspaceId/limits/utilization';
export const UPDATE_COMPANY_LIMIT = 'api/customers/:workspaceId/limits/:limitId';

// Transaction
export const TRANSACTION_SEARCH_API = 'api/transaction/search';
export const TRANSACTIONS_API = 'api/transactions';
export const SCHEDULED_TRANSACTIONS_API = 'api/transactions/scheduled';
export const UPDATE_SCHEDULED_TRANSACTION_API = 'api/transactions/scheduled/:transactionId';
export const UPDATE_TRANSACTION_API = 'api/transactions/:transactionId';
export const TRANSACTION_PAYMENT_REASONS_API = 'api/transactions/payment-reasons';
export const CREATE_TRANSACTION_API = 'api/transactions';
export const CANCEL_SCHEDULED_TRANSACTION_API = 'api/transactions/scheduled/:transactionId';
export const SUSPEND_TRANSACTION_API = 'api/transactions/scheduled/suspend/:transactionId';
export const UNSUSPEND_TRANSACTION_API = 'api/transactions/scheduled/unsuspend/:transactionId';
export const TRANSACTION_DETAILS_API = 'api/transactions/:transactionId';
export const REVERSE_TRANSACTION_API = 'api/transactions/reverse/:transactionId';
export const CANCEL_TRANSACTION_API = 'api/transactions/cancel/:transactionId';
export const SCHEDULED_TRANSACTION_DETAILS_API = 'api/transactions/scheduled/:transactionId';
export const GROUPED_BANK_TRANSACTIONS_API = 'api/customers/:workspaceId/bank-transactions-grouped-by-posted-date';

//  Batch Transaction
export const TRANSACTION_BATCHES_API = 'transactionflow/api/portal/v1/business-accounts/:businessAccountId/transaction-batches';
export const CREATE_BATCHES_API = 'api/transactions/batch';

//  Transaction approval
export const TRANSACTION_APPROVAL_API = 'api/transaction-approval';
export const TRANSACTION_APPROVAL_CURRENT_USER_API = 'api/transaction-approval/currentUser';
export const TRANSACTION_APPROVAL_REVIEW_API = 'api/transaction-approval/:id/review';
export const TRANSACTION_APPROVAL_DETAILS_API = 'api/transaction-approval/:id';

// Workspace resources
export const GENERATE_JWT_TOKEN_API = 'api/security/authenticate';
export const CURRENT_USER_API = 'api/security/currentUser';
export const LOGGED_USER_WORKSPACES_API = 'api/security/userCustomers';
export const USER_PERMISSIONS_API = 'api/security/listPermissions';
export const CREATE_WORKSPACE_API = 'api/customers';
export const UPDATE_WORKSPACE_API = 'api/customers/:workspaceId';
export const CURRENT_WORKSPACE_API = 'api/customers/current';
export const REMOVE_WORKSPACE_API = 'api/customers/:workspaceId';

// Client resources
export const USERS_API = 'api/users';
export const WORKSPACE_USERS_API = 'api/customers/:workspaceId/users';
export const USER_DETAILS_API = 'api/customers/:workspaceId/users/:userId';
export const UPDATE_USER_API = 'api/customers/:workspaceId/user';
export const DELETE_USER_API = 'api/customers/:workspaceId/users/:userId';
export const INVITE_USER_API = 'api/users/invitation/:workspaceId';
export const INVITE_DETAILS_USER_API = 'api/users/invitation/:invitationId';
export const USER_JOIN_INVITATION_API = 'api/users/invitation/:invitationId/join';
export const UPDATE_USER_ROLE_API = 'api/customers/:workspaceId/users/:userId/preferences/roles';
export const UPDATE_USER_NOTIFICATIONS_API = 'api/customers/:workspaceId/users/:userId/preferences/notifications';
export const UPDATE_USER_ALERTS_API = 'api/customers/:workspaceId/users/:userId/preferences/alerts';
export const GET_INVITATIONS_API = 'api/users/invitations/:workspaceId';
export const RESEND_INVITATION_API = 'api/users/invitation/:invitationId/resend';
export const CANCEL_INVITATION_API = 'api/users/invitation/:invitationId';
export const SEND_EMAIL_CONFIRMATION_API = 'api/users/email/confirmation-email';
export const SEND_WELCOME_EMAIL_API = 'api/users/email/welcome-email';
export const SEND_RESET_PASSWORD_EMAIL_API = 'api/users/email/reset-password-email';
export const SEND_RESET_CONFIRMATION_EMAIL_API = 'api/users/email/reset-password-successful-email';
export const USER_FILTER_PREFERENCES_API = 'api/customers/:workspaceId/users/:userId/preferences/filter';

//  Agreements
export const SAVE_AGREEMENTS_API = 'api/user/agreements';
export const BANK_ACCOUNT_AGREEMENTS_API = 'api/customers/bank-accounts/integrated/agreements';
export const BANK_ACCOUNT_AGREEMENT_API = 'api/customers/bank-accounts/integrated/agreements/:agreementId';

//  Rules
export const CREATE_RULE_API = 'api/rules';
export const GET_RULES_API = 'api/rules';
export const GET_RULE_API = 'api/rules/:ruleId';
export const DELETE_RULE_API = 'api/rules/:ruleId';
export const ACTIVATE_RULE_API = 'api/rules/:ruleId/activate';
export const INACTIVATE_RULE_API = 'api/rules/:ruleId/inactivate';
export const GET_ALERT_HISTORY_API = 'api/ruleAlert/history';
export const ARCHIVE_RULE_API = 'api/rules/:ruleId/archive';
export const UNARCHIVE_RULE_API = 'api/rules/:ruleId/unarchive';

//  KYB
export const GET_KYB_APPLICATION = 'api/customers/:customerId/kyb/application';
export const SUBMIT_KYB_APPLICATION = 'api/customers/:customerId/kyb/application';
export const GET_COMPANY_BENEFICIARY = 'api/customers/:customerId/kyb/company-beneficiary';
export const GET_COMPANY_BENEFICIARY_DETAILS = 'api/customers/:customerId/kyb/company-beneficiary/:beneficiaryId';
export const GET_COMPANY_DETAILS = 'api/customers/:customerId/kyb/company-details';
export const GET_COMPANY_DOCUMENTS = 'api/customers/:customerId/kyb/document';
export const SAVE_KYB_COMPANY_DETAILS = 'api/customers/:customerId/kyb/company-details';
export const CREATE_BENEFICIARY = 'api/customers/:customerId/kyb/company-beneficiary';
export const UPDATE_BENEFICIARY = 'api/customers/:customerId/kyb/company-beneficiary/:beneficiaryId';
export const CREATE_COMPANY_DOCUMENT = 'api/customers/:customerId/kyb/document';
export const UPDATE_COMPANY_DOCUMENT = 'api/customers/:customerId/kyb/document/:documentId';
export const GET_COMPANY_DOCUMENT = 'api/customers/:customerId/kyb/document/:documentId';
export const GET_KYB_DETAILS = 'api/customers/:customerId/kyb';

//  Subscription
export const GET_SUBSCRIPTION_API = 'api/subscription';
export const SAVE_SUBSCRIPTION_API = 'api/subscription';
export const UPDATE_SUBSCRIPTION_API = 'api/subscription';
export const GET_SUBSCRIPTION_AGREEMENT_API = 'api/subscription/agreement';

//  Recipient
export const CREATE_RECIPIENT_API = 'api/recipient';
export const UPDATE_RECIPIENT_API = 'api/recipient/:recipientId';
export const DELETE_RECIPIENT_API = 'api/recipient/:recipientId';
export const RECIPIENTS_API = 'api/recipient';
export const RECIPIENT_DETAILS_API = 'api/recipient/:recipientId/account';
export const RECIPIENT_TRANSACTIONS_API = 'api/recipient/:recipientId/transaction';

//  Contact
export const GET_CONTACTS_API = 'api/contacts';
export const GET_CONTACTS_LIST_API = 'api/contacts/list';
export const CONTACT_DETAILS_API = 'api/contacts/:contactId';

//  TopUpFunding
export const GET_ACCOUNT_TOP_UP_FUNDING_API = 'api/top-up-funding/bank-account-to/:accountId';
export const ACTIVATE_ACCOUNT_TOP_UP_FUNDING_API = 'api/top-up-funding/:fundingId/activate';
export const INACTIVATE_ACCOUNT_TOP_UP_FUNDING_API = 'api/top-up-funding/:fundingId/inactivate';
export const CREATE_TOP_UP_FUNDING_API = 'api/top-up-funding';
export const GET_TOP_UP_FUNDING_API = 'api/top-up-funding/:fundingId';
export const GET_ACCOUNT_FUNDING_COUNT_API = 'api/top-up-funding/bank-account-from/count/:accountId';

//  CashFlow
export const CASH_FLOW_ADVANCED_SEARCH_API = 'api/cash-flow/search/advanced';
export const CASH_FLOW_ADVANCED_SEARCH_DOWNLOAD_API = 'api/cash-flow/search/advanced/download';
export const CASH_FLOW_DEBIT_SEARCH_API = 'api/cash-flow/search/debit';
export const CASH_FLOW_CREDIT_SEARCH_API = 'api/cash-flow/search/credit';
export const CASH_FLOW_CONSOLIDATED_SEARCH_API = 'api/cash-flow/search/consolidated';
export const CASH_FLOW_CONSOLIDATED_CHART_API = 'api/cash-flow/search/consolidated/chart';
