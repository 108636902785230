import { Typography } from '@mui/material';
import { FC } from 'react';

import { ChartCategorySettings } from 'models/charts.interface';

import { ItemWrapper, LegendSymbol } from './index.styled';

interface ActivityLegendItemProps {
  legendItem: ChartCategorySettings;
  label: string;
}

const ActivityLegendItem: FC<ActivityLegendItemProps> = ({ legendItem, label }) => (
  <ItemWrapper hasBorder={!!legendItem?.hasDivider}>
    <LegendSymbol legendItem={legendItem} />
    <Typography variant="label1">
      {label}
    </Typography>
  </ItemWrapper>
);

export default ActivityLegendItem;
