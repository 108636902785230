import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/index';

import { ApiStatus } from 'constants/enums';

export const mostActiveBankAccountsSelector = createSelector(
  (state: RootState) => state.mostActiveBankAccounts.accounts,
  (accounts) => ({
    ...accounts,
    isLoading: accounts.status === ApiStatus.loading,
  }),
);
