import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import CustomButton from 'components/CustomButton';
import EmptyState from 'components/EmptyState';
import Loader from 'components/Loader';

import EmptyBankAccounts from 'assets/images/empty-bank-accounts.svg';
import { DASHBOARD_ROUTE } from 'constants/clientRoutes';

import { EmptyStateWrapper } from './index.styled';

interface InsightsEmptyStateProps {
    isLoading: boolean;
}

const InsightsEmptyState: FC<InsightsEmptyStateProps> = ({ isLoading }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <>
      <EmptyStateWrapper>
        <EmptyState
          image={EmptyBankAccounts}
          title={intl.formatMessage({ id: 'label.startByAddingAccounts' })}
          subtitle={intl.formatMessage({ id: 'label.onceAccountsCreatedLinked' })}
          width="350px"
          height="180px"
        >
          <CustomButton
            id="btn-redirect-dashboard"
            label={intl.formatMessage({ id: 'button.goToDashboard' })}
            onClick={() => navigate(DASHBOARD_ROUTE)}
            sx={{ mt: 4 }}
          />
        </EmptyState>
      </EmptyStateWrapper>

      {isLoading && <Loader />}
    </>
  );
};

export default InsightsEmptyState;
