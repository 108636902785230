import { z } from 'zod';

import { DateType } from 'constants/enums';

export const cashflowSchema = z.object({
  dateRange: z.object({
    type: z.string().optional(),
    from: z.number().nullable().optional(),
    to: z.number().nullable().optional(),
  })
    .refine((values) => {
      const { from, to, type } = values;
      return type === DateType.custom ? !!from && !!to : true;
    }, {
      message: 'error.dateRangeMandatoryFields',
    })
    .refine((values) => {
      const { from, to, type } = values;

      if (type !== DateType.custom) {
        return true;
      }

      if (from && to && from > to) {
        return false;
      }

      return true;
    }, {
      message: 'error.dateRange',
    })
    .refine((values) => {
      const { from, to, type } = values;
      const minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setHours(0, 0, 0, 0);
      const maxDate = new Date().setHours(23, 59, 59, 59);

      if (type !== DateType.custom) {
        return true;
      }

      if (from && (from < minDate || from > maxDate)) {
        return false;
      }

      if (to && (to < minDate || to > maxDate)) {
        return false;
      }

      return true;
    }, {
      message: 'error.selectedTimeframe.oneYear',
    }),
});

export type CashflowSchema = z.infer<typeof cashflowSchema>;
